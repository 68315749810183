import { MainNavbarInterface } from '@type/header-types';
import { LogoBjLink } from '@components/layout/LogoBj/LogoBjLink';
import { ErrorBoundary } from '@components/common';
import { useEffect, useState, useMemo } from 'react';
import { SearchSection } from '@components/layout/Header/MainNavbar/search/SearchSection';
import { SearchJobSection } from '@components/layout/Header/MainNavbar/search/SearchJobSection';
import { LanguageList } from '@components/layout/LanguageSwitch/LanguageList';
import { BiaSection } from '@components/layout/Header/MainNavbar/bia/BiaSection';
import { MobileMenu } from '@components/layout/Header/MainNavbar/mobileMenu/MobileMenu';
import { isHireRoute } from '@lib/functions/routes/isHireRoute';
import { getNavbarTheme } from './config';
import { LoginButton, MainMenuLinks } from './links';
import { MessagesSection } from './messages/MessagesSection';
import { ProfileSection } from './profile/ProfileSection';
import { MainNavbarPlaceholder } from './placeholders/MainNavbarPlaceholder';


/**
 * @description Main navbar component.
 * * **headerInfo** - authenticated user info
 * * **navbarTheme** - the navbar theme (transparent, default, etc.)
 * * **currentRoute** - the current route. Used to highlight the active link in the navbar
 * * **notPinned** - if the navbar is not pinned to the top of the page. When we scroll down,
 * the navbar will be pinned to the top of the page, so we can apply a different style to it.
 * * **locale** - the current locale, with default value 'ro'
 */
export const MainNavbar = (props: MainNavbarInterface) => {
  // Main component props with default values
  const {
    headerInfo = undefined,
    headerInfoLoading,
    headerInfoValidating,
    navbarTheme = 'default',
    currentRoute = '',
    notPinned = true,
    locale = 'ro',
  } = props;


  // Check if we can do A/B testing; add a condition here (now 30% of users)
  // *****************************************************
  const canAbTest = useMemo(() => !!(headerInfo && headerInfo?.userId && headerInfo.userId % 2 === 0), [headerInfo]);


  // We make sure that we load the placeholder first.
  // *****************************************************
  const [initialStatus, setInitialStatus] = useState(true);
  useEffect(() => {
    setInitialStatus(false);
  }, []);


  // Conditionally show/hide components
  // *****************************************************
  const show = {
    placeholder: initialStatus || headerInfoLoading,
    revalidation: !initialStatus && !headerInfoLoading && headerInfoValidating,
    mainMenuLinks: !initialStatus && !headerInfoLoading,
    authUser: !initialStatus && !headerInfoLoading && headerInfo,
  };

  const classes = {
    container: 'py-2 md:py-0',
    logoContainer: 'w-full',
    logo: 'mr-3 lg:mr-6',
  };

  // Render the navbar
  // *****************************************************
  return (
    <>
      <div className={getNavbarTheme(navbarTheme, notPinned)}>
        <div className={`container relative flex items-center justify-end md:justify-normal ${classes.container}`}>

          <div
            className={`relative flex items-center justify-start md:w-fit md:justify-between ${classes.logoContainer}`}
          >
            <LogoBjLink
              headerInfo={headerInfo}
              className={`-mt-1 ${classes.logo}`}
            />

            {isHireRoute(currentRoute) && <SearchSection locale={locale} />}
            {!isHireRoute(currentRoute) && <SearchJobSection locale={locale} />}
          </div>

          {/* Placeholder */}
          {show.placeholder && <MainNavbarPlaceholder />}

          {/* Validating new data (SWR has cache) */}
          {show.revalidation && <div className="absolute inset-0 z-10 flex animate-pulse bg-surface/50" />}

          {// Main menu links (both auth and not auth) and the login button for not auth users
            show.mainMenuLinks && (
              <nav className="fixed bottom-0 left-0 z-30 grid h-13 w-full grow grid-cols-[repeat(auto-fit,minmax(10px,1fr))] border-t bg-surface text-xs md:relative md:z-auto md:flex md:h-auto md:items-center md:justify-end md:border-0 md:bg-transparent">

                {/* main menu links */}
                <MainMenuLinks
                  role={headerInfo?.role}
                  currentRoute={currentRoute}
                  locale={locale}
                  canAbTest={canAbTest}
                />

                { show.authUser
                && (
                <ErrorBoundary fallback={<div className="ml-4 mr-2 size-4.5 rounded-full bg-error md:mr-4" />}>
                  <BiaSection />
                </ErrorBoundary>
                )}

                {!show.authUser && (
                  <>
                    <div className="relative ml-5 mt-1 hidden shrink-0 md:block md:px-1">
                      <LanguageList />
                    </div>

                    {/* Desktop Login button */}
                    <LoginButton className="hidden md:flex" />
                  </>
                )}

                { // User is authenticated
                  show.authUser && (
                    <>
                      {/* Messages */}
                      <ErrorBoundary fallback={<div className="ml-4 mr-2 size-4.5 rounded-full bg-error md:mr-4" />}>
                        <MessagesSection
                          className="relative ml-5 hidden md:block"
                          locale={locale}
                          unseenMessages={headerInfo?.nrUnseenMessages}
                        />
                      </ErrorBoundary>

                      {/* Profile */}
                      <ProfileSection
                        headerInfo={headerInfo}
                        locale={locale}
                        className="ml-5 hidden shrink-0 md:relative md:block lg:ml-7"
                      />
                    </>
                  )
                }

                {/* Mobile menu */}
                <MobileMenu
                  className="flex shrink-0 items-center justify-center md:hidden"
                  headerInfo={headerInfo}
                  currentRoute={currentRoute}
                  locale={locale}
                />
              </nav>
            )
          }
        </div>
      </div>

      {/* This is used to calculate the height of the opened mobile menu */}
      <div id="menuOffsetSentinel" className="pointer-events-none absolute top-full h-0 w-full" />
    </>
  );
};
