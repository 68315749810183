import { Middleware } from 'swr';
import { unstable_serialize, SWRInfiniteKeyLoader } from 'swr/infinite';
import { SwrInfiniteRevalidateFn } from '@type/swr';
import { useState, useEffect, useRef } from 'react';
import { getWithFlatKeys } from '@utils/flatten-expand-object/flatten-expand-object';


/**
 * @description Helper function used to return the array from an object key.
 * @param data - the data object.
 * @param key - the key to get the array from.
 *
 * @example
 * const obj = {
 *   'key1_keyA': 'value 1',
 *   'key2_0': 'text 1',
 *   'key2_1': 'text 2',
 *   'key3_a_b_c': 2
 * };
 *
 * arrayFromObjectKey(obj, 'key2'); // ['text 1', 'text 2']
 */
export const getArrayFromObjectKey = (data: object, key: string) => {
  const array = getWithFlatKeys(data, key);
  return Array.isArray(array) ? array : undefined;
};


/**
 * @description SWR Middleware with wrapper for number of items per page. We extend the SWRInfiniteResponse with our custom properties,
 * namely isEmpty and isReachingEnd. Keep in mind that you will have to extend the SWRInfiniteResponse with our custom properties in
 * the hook where you use this middleware.
 *
 * **Parameters:**
 * - **nrItems** - number of items per page.
 * - **responseArrayKey** - the key where the array of items is located in the response (ie: 'items' - from obj?.items). Default 'errors'.
 *
 * **Returns:** the SWR hook with our custom properties.
 * - **isEmpty** - boolean. The list is an empty array, there are no results.
 * - **showLoadMore** - boolean. The list has fewer items than the page limit, we reached the end. Use it to show/hide the load more button.
 * - **sizeAsRef** - RefObject<number>. Size as Ref. We need a ref for situations in which we need to access the size value inside the useEffect (refs don't trigger re-renders).
 * - **mutatePage** - SwrInfiniteMutatePageType<DataType>. Mutate specific page(s) based on a compare function. It compares the data inside the page.
 *
 * @example
 * // Use it like this in API options:
 * {
 *  use: [infiniteHelpers(nrItems)],
 *  ...apiOptions,
 * }
 *
 */
export const infiniteHelpers = (nrItems = 10, responseArrayKey?: string): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);

  // Get the cache key (for SWR Infinite is the series key '@inf', not the page key '@')
  const currentSeriesKey = unstable_serialize(key as SWRInfiniteKeyLoader);


  // *******************************************************
  // A. Custom properties: isEmpty, showLoadMore, sizeAsRef.
  // *******************************************************

  // 1. The list is an empty array, there are no results.
  // 2. The list has fewer items than the page limit, we reached the end. Use it to show/hide the load more button.
  const [isEmpty, setIsEmpty] = useState<boolean | undefined>(undefined);
  const [showLoadMore, setShowLoadMore] = useState<boolean | undefined>(undefined);

  // Size as Ref. We need a ref for situations in which we need to access the size value inside the useEffect (refs don't trigger re-renders).
  const sizeAsRef = useRef<number>(1);


  // Check if we have data, and we reached the end of the list, or we have an empty array as response
  // *******************************************************
  useEffect(() => {
    // Array.isArray(swr?.data) && swr?.data?.length > 0 => because swr.data is an array of things. You should get an empty
    // array or an object from the API as response to be considered valid (so, an array with an empty array or object inside).
    if (swr?.data !== undefined && Array.isArray(swr?.data) && swr?.data?.length > 0) {
      // Get the last page. We need to check if the last page is an array or an object. If it's an object, we need to
      // get the array from the responseArrayKey key (if we have one) or from the 'errors' key.
      const lastPageBase = swr.data.at(-1) as unknown;
      const lastPage = Array.isArray(lastPageBase) ? lastPageBase : getArrayFromObjectKey(lastPageBase as object, responseArrayKey || 'errors');

      // Set the size as ref
      sizeAsRef.current = swr?.data?.length;

      // Check if we're on the first page and we have an empty array as response. If we have an empty array, then the list is empty.
      setIsEmpty(swr?.data?.length === 1 && Array.isArray(lastPage) && lastPage?.length === 0);

      // If we have data and the nr of items is not less than the page limit then we can load more items. Fewer items means we reached the end.
      setShowLoadMore(Array.isArray(lastPage) && !(lastPage?.length < nrItems));
    }
  }, [swr?.data]);


  // Reset show load more when the key changes (because we're loading a new list).
  // *******************************************************
  useEffect(() => {
    setShowLoadMore(undefined);
  }, [currentSeriesKey]);


  // *******************************************************
  // B. mutatePage function. Mutate specific page(s) based on a compare function. It compares the data inside the page.
  // *******************************************************
  /**
   * @description Mutate specific page(s) based on a compare function. It compares the data inside the page.
   * @param compareFn - the compare function.
   *
   * @example
   * // It will mutate only the first and second page.
   * void mutatePage((data) => data?.page === 1 || data?.page === 2);
   */
  const mutatePage = (compareFn: SwrInfiniteRevalidateFn<typeof swr.data>) => {
    void swr.mutate(undefined, {
      revalidate: compareFn,
    });
  };

  // Return data with our extra custom properties
  return {
    ...swr,
    isEmpty,
    showLoadMore,
    sizeAsRef,
    mutatePage,
  };
};
