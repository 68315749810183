import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Dispatch, SetStateAction } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import {
  FormElementCustomValidityMessages,
  RecordInterface,
  RecordOptionsInterface,
  StoredElementsInterface,
} from '@type/form-types';
import { ComboBox } from '../Combobox/ComboBox';

const googleAPIKey = process.env.GOOGLE_MAPS_CLIENT_API_KEY as string;

interface IProps {
  locationAddress?: string;
  setLocationAddress?: Dispatch<SetStateAction<string>>;
  record?: RecordInterface;
  recordName?: string;
  recordOptions?: RecordOptionsInterface;
  customValidityMessages?: FormElementCustomValidityMessages;
}

/**
 * @description Component used for searching an address using google maps places api
 *
 * @example 1 using state
 *
 * const [locationAddress, setLocationAddress] = useState<string>('');
 * ...
 * <GooglePlacesInput
 *   locationAddress={locationAddress}
 *   setLocationAddress={setLocationAddress}
 * />
 *
 * @example 2 using record
 *
 * ...
 * <GooglePlacesInput
 *   record={record}
 *   recordName="resumeLocation"
 * />
 *
 * @param locationAddress - if you want to use this component with useState hook
 * @param setLocationAddress - if you want to use this component with useState hook
 * @param record - if you want to use this component with useBjForm hook
 * @param recordName - if you want to use this component with useBjForm hook
 * @param recordOptions - if you want to use this component with useBjForm hook
 * @param customValidityMessages - if you want to use this component with custom validity messages
 * @constructor
 */
export const GooglePlacesInput = ({
  locationAddress, setLocationAddress, record, recordName, recordOptions, customValidityMessages,
}: IProps) => {
  const {
    placePredictions,
    getPlacePredictions,
  } = useGoogle({
    apiKey: googleAPIKey,
  });

  const extraProps: StoredElementsInterface = record ? record(recordName ?? 'location', recordOptions) : {} as StoredElementsInterface;
  if (locationAddress !== undefined) {
    extraProps.defaultValue = locationAddress;
  }

  return (
    <div className="relative">
      {/* autocomplete input */}
      <ComboBox
        {...extraProps}
        required
        customValidityMessages={customValidityMessages}
        className="pr-9"
        options={{
          staticList: placePredictions,
          valueFromKey: 'description',
          showResetButton: false,
          showDropdownButton: false,
          externalGetQuery: async (keywords: string) => {
            getPlacePredictions({ input: keywords });
          },
        }}
        onChange={(evt) => {
          if (setLocationAddress) {
            setLocationAddress(evt.target.value);
          }
        }}
      />

      {/* right icon */}
      <div className="absolute right-0 top-3">
        <div className="pr-3.5">
          <MapPinIcon className="h-5" />
        </div>
      </div>
    </div>
  );
};
