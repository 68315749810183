import { Transition } from '@headlessui/react';
import { useAppSelector } from '@store/index';
import { getPaymentData, getOrderStatus, getOrderData } from '@store/reducers/employer/subscription/subscriptionSelector';
import {
  StepNotEnoughCredits, StepPaymentStatus, StepSelectUpgradePackage, StepPaySelectedPackage,
} from './flow-steps';


// Common transition for all steps
// *****************************************
const commonTransition = {
  enter: 'transition-all duration-300 delay-75',
  enterFrom: 'opacity-0 translate-y-2 min-h-96',
  enterTo: 'opacity-100 translate-y-0 min-h-max',
  leave: 'transition-all duration-300 absolute inset-0',
  leaveFrom: 'opacity-100 translate-y-0 min-h-max',
  leaveTo: 'opacity-0 translate-y-2 min-h-96 hidden',
};


/**
 * @description Renders the upgrade subscription hub view, with payment options, pricing and if
 * it's the case the 'not enough credits' and payment status possible steps.
 *
 * * **closeModal** - The function to close the modal.
 *
 * @example
 * // usage in a modal
 * import { Button, Modal } from '@components/common';
 * import { useBjModal } from 'src/hooks';
 *
 * <Button onClick={openModal}>Open upgrade packages modal</Button>
 * <Modal
 *   isOpen={isOpen}
 *   onClose={closeModal}
 *   size="lg"
 *   header={t('subscription.limit.reached.title')}
 *   body={<UpgradeSubscriptionHub closeModal={closeModal} />}
 * />
 */
export const UpgradeSubscriptionHubModal = ({ closeModal }: { closeModal?: () => void }) => {
  // 1. Payment data shows the payment options and pricing.
  // 2. Order data shows the order data (after ordering in payment step).
  // 3. Order status shows the status of the order (paid ok or error).
  // *****************************************
  const paymentData = useAppSelector(getPaymentData);
  const orderData = useAppSelector(getOrderData);
  const orderStatus = useAppSelector(getOrderStatus);


  // Show conditions for each step
  // *****************************************
  const showStep = {
    choosePackage: !paymentData && !orderStatus && !orderData?.shoppingCartId,
    paymentOptions: (paymentData && !orderStatus && !orderData?.shoppingCartId) || orderStatus === 'error',
    buyCredits: orderData?.shoppingCartId,
    status: orderStatus === 'success',
  };

  // Render component
  // *****************************************
  return (
    <div className="relative min-h-96 bg-surface-100">

      {/* STEP 1: Choose a upgrade package
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.choosePackage)}
        as="div"
        {...commonTransition}
      >
        <StepSelectUpgradePackage />
      </Transition>

      { /* STEP 2: Payment options for the selected upgrade
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.paymentOptions)}
        as="div"
        {...commonTransition}
      >
        <StepPaySelectedPackage paymentData={paymentData} />
      </Transition>

      { /* STEP 3: Not enough credits: the user chose to pay from the
           wallet but the credits are insufficient
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.buyCredits)}
        as="div"
        {...commonTransition}
      >
        <StepNotEnoughCredits shoppingCartId={orderData?.shoppingCartId} />
      </Transition>

      { /* STEP 4: Show status if payment was done automatically - not redirected to Stripe or buy credits page -
           (step conditions: the user has a saved credit card or enough credits in wallet)
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.status)}
        as="div"
        {...commonTransition}
      >
        <StepPaymentStatus
          orderStatus={orderStatus}
          packageName={paymentData?.paymentTitle}
          closeModal={closeModal}
        />
      </Transition>

    </div>
  );
};
