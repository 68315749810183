import {
  useMemo, Fragment,
  Ref, ElementType, ReactNode, CSSProperties,
} from 'react';
import {
  useFloating, autoUpdate, flip, shift,
  OffsetOptions, Strategy, Placement, UseFloatingReturn, Middleware, MiddlewareData,
  offset as floatOffset,
  inline as floatInline,
  size as floatSize,
} from '@floating-ui/react';


/** Types */
interface FloatProps {
  show: boolean,
  ref?: Ref<HTMLElement>,
  as?: ElementType,
  placement?: Placement,
  offset?: OffsetOptions,
  strategy?: Strategy,
  inline?: boolean,
  autoUpdatePosition?: boolean,
  preventOverflow?: boolean,
  children?: (props: FloatRenderProps) => ReactNode,
}

interface FloatRenderProps {
  reference: UseFloatingReturn['refs']['setReference'],
  floatingRef: UseFloatingReturn['refs']['setFloating'],
  floatingStyles: CSSProperties,
  placement: Placement,
  middlewareData: MiddlewareData,
}


/**
 * @description Floating UI component. It uses the Floating UI library to create a floating element.
 * - `show`: *boolean* - Show or hide the floating element.
 * - `ref`: *Ref<HTMLElement>* - Ref for the floating element.
 * - `as`: *ElementType* - Element type for the floating element. Default is 'Fragment'.
 * - `placement`: *Placement* - Placement of the floating element. Default is 'bottom'.
 * - `offset`: *OffsetOptions* - Offset of the floating element. Default is 0.
 * - `strategy`: *Strategy* - Strategy for the floating element. Default is 'absolute'.
 * - `inline`: *boolean* - If true, the floating element will be inline with the reference element. Default is false.
 * - `autoUpdatePosition`: *boolean* - If true, the position of the floating element will be updated automatically. Default is true.
 * - `preventOverflow`: *boolean* - If true, the floating element will not overflow the viewport. Default is true.
 * - `children`: *function* - Function to render the floating element. It receives the following props:
 *   - `reference`: *UseFloatingReturn['refs']['setReference']* - Ref for the reference element.
 *   - `floating`: *UseFloatingReturn['refs']['setFloating']* - Ref for the floating element.
 *   - `floatingStyles`: *CSSProperties* - Styles for the floating element.
 *   - `placement`: *Placement* - Placement of the floating element.
 *   - `middlewareData`: *MiddlewareData* - Middleware data for the floating element.
 *
 * @example
 * // Basic usage
 * <Float
 *  show={show}
 *  placement="bottom"
 *  offset={10}
 *  strategy="absolute"
 * >
 *  {({ reference, floatingRef, floatingStyles }) => (
 *    <div
 *      ref={reference}
 *      style={floatingStyles}
 *    >
 *      Anchoring element
 *    </div>
 *
 *    <div
 *      ref={floatingRef}
 *      style={floatingStyles}
 *      className="bg-white p-4 rounded shadow-lg"
 *    >
 *      Floating element
 *    </div>
 *  )}
 * </Float>
 *
 * // If you want to show the floating element in a parent with overflow: hidden,
 * // strategy="fixed".
 */
export const Float = (props: FloatProps) => {
  // Destructure props
  const {
    show,
    ref,
    as = Fragment,
    placement = 'bottom',
    offset = 0,
    strategy = 'absolute',
    inline = false,
    autoUpdatePosition = true,
    preventOverflow = true,
    children,
    ...rest
  } = props;


  // Floating UI middleware
  // ***************************
  const floatMiddleware = useMemo(() => {
    const middleware: Middleware[] = [
      flip(),
      shift(),
      floatOffset(offset),
    ];

    // Add inline middleware if needed
    if (inline) middleware.push(floatInline());

    // Add preventOverflow middleware if needed
    if (preventOverflow) {
      middleware.push(floatSize({
        apply: ({ elements, availableHeight, availableWidth }) => {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }));
    }

    return middleware;
  }, [inline, offset, preventOverflow]);


  // Floating UI
  // ***************************
  const {
    refs,
    floatingStyles,
    placement: floatingPlacement,
    middlewareData,
  } = useFloating({
    open: show,
    transform: false,
    placement,
    strategy,
    middleware: floatMiddleware,
    whileElementsMounted: autoUpdatePosition ? autoUpdate : undefined,
  });


  // Props for render function
  // ***************************
  const renderProps: FloatRenderProps = {
    reference: refs.setReference,
    floatingRef: refs.setFloating,
    floatingStyles,
    placement: floatingPlacement,
    middlewareData,
  };


  // Render
  // ***************************
  const RenderComponent = as;
  return (
    <RenderComponent
      {...ref && RenderComponent !== Fragment ? { ref } : {}}
      {...rest}
    >
      {children && children(renderProps)}
    </RenderComponent>
  );
};
