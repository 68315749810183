import {
  Tab, TabGroup, TabList, TabPanels,
} from '@headlessui/react';
import { TabStyledPanel as TabPanel } from './TabStyledPanel';
import { TabStyledButton as TabButton } from './TabStyledButton';


/**
 * @description Customized Tab component. It has a default Panel component that is customized
 * with animations. This component is extended from the HeadlessUI Tab, so it has all the same
 * props and types (and some extras as previously said).
 *
 * See more examples at http://localhost:3000/temp/tabs
 *
 * @example
 * // Using TabStyled instead of TabStyledButton
 * <TabStyledGroup as="div">
 *   <TabStyledList>
 *     <TabStyled as={Fragment}>
 *       {({ selected }) => (
 *         <Button size="sm" styling={selected ? 'solid' : 'outline'} className="outline-0 rounded-l-full" rounding="none">Tab 1</Button>
 *       )}
 *     </TabStyled>
 *     <TabStyled as={Fragment}>
 *       {({ selected }) => (
 *         <Button size="sm" styling={selected ? 'solid' : 'outline'} className="outline-0 rounded-r-full" rounding="none">Tab 2</Button>
 *       )}
 *     </TabStyled>
 *   </TabStyledList>
 *
 *   <TabStyledPanels className="bg-surface rounded p-6">
 *     <TabStyledPanel animation="slideUp">Panel 1</TabStyledPanel>
 *     <TabStyledPanel animation="slideUp">Panel 2</TabStyledPanel>
 *   </TabStyledPanels>
 * </TabStyledGroup>
 *
 * // Using TabStyledButton instead of TabStyled
 * <TabStyledGroup as="div">
 *   <TabStyledList>
 *     <TabStyledButton rounding="none" size="sm">Tab 1</TabStyledButton>
 *     <TabStyledButton rounding="none" size="sm">Tab 2</TabStyledButton>
 *   </TabStyledList>
 *
 *   <TabStyledPanels className="border p-4">
 *     <TabStyledPanel>Panel 1</TabStyledPanel>
 *     <TabStyledPanel>Panel 2</TabStyledPanel>
 *   </TabStyledPanels>
 * </TabStyledGroup>
 */
export const TabStyled = Tab;
export const TabStyledList = TabList;
export const TabStyledButton = TabButton;
export const TabStyledPanels = TabPanels;
export const TabStyledPanel = TabPanel;
export const TabStyledGroup = TabGroup;
