import {
  Fragment, ReactNode, ElementType, JSX,
} from 'react';
import {
  TabPanel, Transition, TabPanelProps, TransitionEvents,
} from '@headlessui/react';
import { composeTabPanelAnimation } from './tabStyles';


/** Types */
type TabStyledPanelProps = TabPanelProps<ElementType<JSX.Element>> & TransitionEvents & {
  children: ReactNode,
  className?: string,
  unmount?: boolean,
  animation?: 'none' | 'fade' | 'slideUp' | 'slideLeft',
};


/**
 * @description Customized TabPanel component. It has a default animation and UI styles.
 * - `animation` - Animation for the component. Default is 'fade'.
 * - `unmount` - If true, the component will be unmounted when not selected. Default is true.
 * - `className` - Any additional classes to add to the component.
 *
 * **Mount events:**
 * - `beforeEnter` - Function to be executed before the component enters.
 * - `afterEnter` - Function to be executed after the component enters.
 * - `beforeLeave` - Function to be executed before the component leaves.
 * - `afterLeave` - Function to be executed after the component leaves.
 */
export const TabStyledPanel = (props: TabStyledPanelProps) => {
  const {
    children,
    className = '',
    animation = 'fade',
    // this is for Transition
    unmount = true,
    beforeEnter,
    beforeLeave,
    afterEnter,
    afterLeave,
    ...rest
  } = props;

  // component animation
  const componentAnimation = composeTabPanelAnimation(animation);

  // Render component
  // **************************
  return (
    <TabPanel
      as={Fragment}
      unmount={false}
      {...rest}
    >
      {({ selected }) => (
        /* Use the internal `selected` state to conditionally animate the selected panel. */
        <Transition
          as="div"
          className={className}
          show={selected}
          unmount={unmount}
          {// conditional animation
            ...animation !== 'none' ? {
              enter: 'transition ease-out duration-500',
              enterFrom: componentAnimation.hiddenState,
              enterTo: componentAnimation.visibleState,
              leave: 'transition-none duration-0',
              leaveFrom: componentAnimation.visibleState,
              leaveTo: componentAnimation.hiddenState,
            } : {}
          }
          {...beforeEnter && { beforeEnter }}
          {...afterEnter && { afterEnter }}
          {...beforeLeave && { beforeLeave }}
          {...afterLeave && { afterLeave }}
        >
          { children }
        </Transition>
      )}
    </TabPanel>
  );
};
