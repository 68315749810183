import { cn } from '@utils/cn';

// Interfaces
// *******************************************************************************************
interface AnimationGroup {
  hiddenState: string,
  visibleState: string,
}

interface PanelAnimation {
  fade: AnimationGroup,
  slideUp: AnimationGroup,
  slideDown: AnimationGroup,
}

interface Position {
  [position: string]: string,
}

interface Ui {
  [ui: string]: string,
}

interface PanelStyles {
  panelPosition: Position,
  panelUi: Ui,
}


// Classes
// *******************************************************************************************
// Popover panel animation
export const panelAnimation: PanelAnimation = {
  fade: {
    hiddenState: 'scale-95 opacity-0',
    visibleState: 'scale-100 opacity-100',
  },
  slideUp: {
    hiddenState: 'opacity-0 translate-y-1',
    visibleState: 'opacity-100 translate-y-0',
  },
  slideDown: {
    hiddenState: 'opacity-0 -translate-y-1',
    visibleState: 'opacity-100 translate-y-0',
  },
};

// Popover panel position
const panelPosition = {
  left: 'left-0 origin-top-left',
  center: 'left-1/2 -translate-x-1/2 origin-top',
  right: 'right-0 origin-top-right',
  anchored: '',
};

// Popover panel styles
const panelUi = {
  none: '',
  default: 'absolute z-10 rounded-sm bg-surface shadow-lg border-t border-input/15 focus:outline-none',
  anchored: 'z-10 rounded-sm bg-surface shadow-lg border-t border-input/15 focus:outline-none',
};


// Object with popover panel styles
// *******************************************************************************************
export const panelStyles: PanelStyles = {
  panelPosition,
  panelUi,
};


/**
 * @description Compose popover panel classes
 * @param position
 * @param ui
 * @param className
 */
export const composePopoverPanelClasses = (position: string, ui: string, className: string): string => {
  const positionClass = panelStyles.panelPosition[position];
  const uiClass = panelStyles.panelUi[ui];

  // if ui is 'none' we don't need to add any classes, define them on component
  return ui === 'none' ? className : cn(uiClass, positionClass, className);
};


/**
 * @description Compose popover panel animation
 * @param animation
 */
export const composePopoverPanelAnimation = (animation: keyof PanelAnimation): AnimationGroup => panelAnimation[animation];
