import {
  useState, useMemo, Dispatch, SetStateAction,
} from 'react';
import { useTranslation } from 'next-i18next';
import {
  Button, ListboxStyled, ListboxStyledButton, ListboxStyledOptions, ListboxStyledOption,
} from '@components/common';
import { ChevronDownIcon } from '@heroicons/react/24/outline';


// Interfaces
// *****************************************
interface ChooseCreditCardProps {
  getSelectedPaymentCard?: Dispatch<SetStateAction<number>>,
  userSavedCreditCards?: ApiSchema<'CreditCardRead'>[] | undefined,
  className?: string,
}


/**
 * @description Renders the list of credit cards to choose from in ListboxStyled component.
 * * **getSelectedPaymentCard** is the callback to get the selected payment card. It's a state setter.
 * * **userSavedCreditCards** is the list of credit cards.
 * * **className** extra classes you might want to pass on the list.
 */
export const ChooseCreditCard = (props: ChooseCreditCardProps) => {
  // Props destructuring
  const {
    getSelectedPaymentCard,
    userSavedCreditCards,
    className,
  } = props;

  // Translation
  const { t } = useTranslation('common');


  // Add 'new credit card' to the list of available cards. It will be the last one.
  // *****************************************
  const savedCreditCards = useMemo(() => ([
    ...userSavedCreditCards || [],
    {
      id: 0,
      maskedNumber: t('label-payment.new-credit-card'),
      lastActive: false,
      recurrent: false,
    },
  ]), [userSavedCreditCards, t]);


  // Find the default recurrent card (lastActive: true) or the first one
  // *****************************************
  const defaultCard = useMemo(() => (
    savedCreditCards?.filter((card) => card.lastActive)[0]
    || savedCreditCards[0]
  ), [savedCreditCards]);


  // State for credit card selector Listbox
  // *****************************************
  const [selectedCard, setSelectedCard] = useState(defaultCard);


  // Update the selected card in component and StepPaySelectedPackage
  // *****************************************
  const updateSelectedCard = (card: ApiSchema<'CreditCardRead'>) => {
    setSelectedCard(card);
    if (getSelectedPaymentCard && card?.id?.toString()) {
      getSelectedPaymentCard(card?.id);
    }
  };


  // Render component
  // *****************************************
  return (
    <ListboxStyled
      value={selectedCard}
      onChange={updateSelectedCard}
      {...className ? { className } : {}}
    >
      <ListboxStyledButton
        as={Button}
        styling="text"
        color="pink"
        size="sm"
        rounding="full"
        className="border px-4 py-1 font-semibold outline-0"
      >
        {selectedCard.maskedNumber}
        <ChevronDownIcon className="ml-2 size-4 stroke-2" />
      </ListboxStyledButton>

      {/* Listbox options */}
      <ListboxStyledOptions
        className="mt-px min-w-48 divide-y overflow-hidden !rounded-md"
        animation="slideDown"
        position="left"
      >
        {savedCreditCards.map((creditCard) => (
          <ListboxStyledOption
            key={creditCard.id}
            value={creditCard}
          >
            <div className="flex items-center gap-2 px-5 py-2.5 text-ink-medium hover:bg-primary-light hover:text-white ui-selected:text-primary-light">
              {creditCard.maskedNumber}
            </div>
          </ListboxStyledOption>
        ))}
      </ListboxStyledOptions>
    </ListboxStyled>
  );
};
