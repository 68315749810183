import { useEffect, useRef } from 'react';

export interface DropdownHoverProps {
  enabled: boolean
}

export const useDropdownHover = (props: DropdownHoverProps) => {
  const { enabled = true } = props;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closeFuncRef = useRef<() => void>(undefined);

  useEffect(() => {
    if (!enabled) return () => {};

    const dropdown = dropdownRef.current;
    const button = buttonRef.current;

    if (!dropdown || !button) {
      console.warn('useDropdownHover: Please assign dropdownRef and buttonRef for this to function correctly.');
      return () => {};
    }

    const onMouseEnter = () => {
      button.click();
    };

    const onMouseLeave = () => {
      if (closeFuncRef.current) {
        closeFuncRef.current();
      }
    };

    dropdown.addEventListener('mouseenter', onMouseEnter);
    dropdown.addEventListener('mouseleave', onMouseLeave);

    return () => {
      dropdown.removeEventListener('mouseenter', onMouseEnter);
      dropdown.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [enabled]);

  return { dropdownRef, buttonRef, closeFuncRef };
};
