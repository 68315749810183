import { Ref } from 'react';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuSection,
  MenuHeading,
  MenuSeparator,
  MenuProps,
} from '@headlessui/react';
import { DropdownPanel } from '@components/common/Dropdown/DropdownPanel';


/** Types */
interface DropdownProps extends MenuProps {
  className?: string;
  ref?: Ref<HTMLDivElement>;
}


/**
 * @description Customized Menu component. It has a default Items component that is customized
 * with animations, positions and UI styles. This component is extended from the HeadlessUI Menu,
 * so it has all the same props and types (and some extras as previously said).
 *
 * **Mount events for DropdownItems:**
 * - `beforeEnter` - Function to be executed before the component enters.
 * - `afterEnter` - Function to be executed after the component enters.
 * - `beforeLeave` - Function to be executed before the component leaves.
 * - `afterLeave` - Function to be executed after the component leaves.
 *
 * @example
 * <Dropdown>
 *
 *   // Toggle: model for MenuButton using the prop "as" to render our own component
 *   <DropdownButton as={Button} styling="text" size="sm" color="Ink" className="outline-0">
 *     Toggle dropdown
 *   </DropdownButton>
 *
 *   // DropdownItems: this is the customized MenuItems component. Extra props (from the original
 *   // HeadlessUI ListboxStyledOptions component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // If you're using the `anchor` prop, put position="anchored" ui="anchored" or just don't pass them at all.
 *   <DropdownItems animation="slideUp" anchor="bottom end" className="mt-1">
 *
 *     // On click this will close the dropdown
 *     <DropdownItem as={LinkStyled} href="/some-page" styling="text" color="secondary">
 *       Link in dropdown
 *     </DropdownItem>
 *
 *   </DropdownItems>
 * </Dropdown>
 *
 * // When the parent has `overflow: hidden` use the `anchor` prop to set the position of the DropdownItems
 * // For floated elements, use the min-w-[var(--button-width)] to set the width of the dropdown same as the button
 * <DropdownItems
 *    animation="slideDown"
 *    anchor="bottom start"
 *    className="mt-1 min-w-[var(--button-width)]"
 * >
 *   ...
 * </DropdownItems>
 *
 * // When the parent has NO `overflow: hidden` use the `position` prop to set the position of the DropdownItems
 * <DropdownItems
 *   animation="slideDown"
 *   className="mt-1"
 *   position="right"
 * >
 *   ...
 * </DropdownItems>
 */
export const Dropdown = (props: DropdownProps) => {
  const {
    className,
    ...rest
  } = props;

  return (
    <Menu as="div" className={className || 'relative'} {...rest} />
  );
};

export const DropdownButton = MenuButton;
export const DropdownItems = DropdownPanel;
export const DropdownItem = MenuItem;
export const DropdownSection = MenuSection;
export const DropdownHeading = MenuHeading;
export const DropdownSeparator = MenuSeparator;
