'use client';

import { useEffect, RefObject, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { Spinner } from '@components/common/Elements/Spinner';
import { NoResults } from './NoResults';


// Interface
// ******************************************
interface InfiniteListProps {
  autoload?: number | 'infinite',
  renderList: () => ReactNode,
  renderButton: () => ReactNode,
  infiniteProps: {
    setSize: (size: number | ((_size: number) => number)) => Promise<unknown[] | undefined>,
    isEmpty?: boolean,
    showLoadMore?: boolean,
    sizeAsRef?: RefObject<number>,
    isLoading?: boolean,
  }
  isEmptyLabels?: {
    isEmptyTitle?: string,
    isEmptyText?: string,
  },
  renderEmptyState?: () => ReactNode
}


/**
 * @description Simple infinite list render component. It uses Intersection Observer to trigger the next page autoload.
 * You need to use it together with useSwrInfinite.
 *
 *
 * - **autoload** - The number of auto-fetches after which the button will be shown. If set to 'infinite', the button will never be shown and the list will always autoload.
 * Otherwise, we'll show a loading spinner and the button will be shown after the specified autoload number. For example, if set to 3, the button will be shown after the 3rd fetch request
 * for that SWR key. If the SWR key is changed, the counter will be reset (we want this for filtering). Default: 3.
 * - **renderList** - Render prop function. This is used to render the list. Required.
 * - **renderButton** - Render prop function. This is used to render the button. Required.
 *
 * **infiniteProps** - The infiniteProps object from useSwrInfinite. Required.
 * - **setSize** - The setSize function from useSwrInfinite. This is used to trigger the next page autoload. Required.
 * - **sizeAsRef** - The sizeAsRef state from useSwrInfinite. This is used to check if we need to show the button. Required.
 * - **isEmpty** - The isEmpty state from useSwrInfinite. If true, the list will not be rendered. Default: undefined.
 * - **showLoadMore** - The showLoadMore state from useSwrInfinite. If true, the button will be shown as long as we not autoload. Default: true.
 * - **isValidating** - The isValidating state from useSwrInfinite. If true, the spinner will be shown when we autoload. Default: undefined.
 *
 * **isEmptyLabels** - The labels for the empty state. Default: undefined.
 * - **title** - The title for the empty state. Default: undefined.
 * - **text** - The text for the empty state. Default: undefined.
 *
 * - **renderEmptyState** - Render prop function. This is used to render a custom empty state. If used, the `isEmptyLabels` prop will be ignored. Optional.
 *
 * @example
 * <InfiniteList
 *   autoload={3}
 *   infiniteProps={{ setSize, isEmpty, showLoadMore, sizeAsRef, isLoading }}
 *   isEmptyLabels={{ title: 'no_results', text: 'cv.search.message.no_result' }}
 *   renderList={() => (
 *     <div className="grid gap-3 my-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
 *       {
 *         data?.map((group) => (
 *           group?.items?.map((item) => (
 *             <div key={item.id} className="bg-surface border rounded-md px-6 py-16">
 *               <div className="font-bold">{item?.privateInformation?.name || 'Hidden'}</div>
 *               {item?.location}
 *             </div>
 *           ))
 *         ))
 *       }
 *     </div>
 *   )}
 *   renderButton={() => (
 *     <div className="text-center my-8">
 *       <Button isLoading={isValidating} rounding="full" color="secondary" onClick={() => { void setSize(size + 1); }}>
 *         {t('dashboard.label.notification_show')}
 *       </Button>
 *     </div>
 *   )}
 * />
 *
 *
 * // Real usage with Paginator, TalentCard and useFiltersQueryParameters
 * // ************************************************************************************
 * // This will modify the page number in the URL when the user scrolls down or up the page. The initial page should
 * // be taken via getServerSideProps.
 * const { setCurrentPage } = useFiltersQueryParams({ initialPage: initialPageNumber });
 *
 * // The infinite list
 * <InfiniteList
 *   autoload={3}
 *   infiniteProps={{ setSize, isEmpty, showLoadMore, sizeAsRef, isLoading }}
 *   isEmptyLabels={{ title: 'no_results', text: 'cv.search.message.no_result' }}
 *   renderList={() => (
 *     <div className="grid gap-4 my-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:my-6">
 *       {
 *         data?.map((pageGroup) => {
 *           const viewportPage = pageGroup?.page || 1;
 *           return (
 *             pageGroup?.items?.map((item, index) => (
 *               <Paginator itemIndex={index} currentPage={viewportPage} key={item.id} callback={setCurrentPage}>
 *                 <TalentCard key={item.id} cardData={item} cardSpotlight="experience" />
 *               </Paginator>
 *             ))
 *           );
 *         })
 *       }
 *     </div>
 *   )}
 *   renderButton={() => ( ... )}
 * />
 */
export const InfiniteList = (props: InfiniteListProps) => {
  // Destructure props
  const {
    autoload = 3,
    renderList,
    renderButton,
    infiniteProps,
    isEmptyLabels,
    renderEmptyState,
  } = props;

  // Destructure infiniteProps
  const {
    setSize,
    isEmpty,
    showLoadMore,
    sizeAsRef,
    isLoading,
  } = infiniteProps;

  // Destructure isEmptyLabels
  const {
    isEmptyTitle,
    isEmptyText,
  } = isEmptyLabels || {};


  // Helper variables
  // ************************************************************************************
  const refSize = sizeAsRef?.current || 1;
  const showButton = showLoadMore && autoload !== 'infinite' && refSize >= autoload;


  // Intersection observer autoload watcher
  // ************************************************************************************
  const [autoloadRef, autoloadInView] = useInView({
    threshold: 0,
  });


  // Trigger autoload more via Intersection Observer. Wait for states to be updated (the timeout)
  // If showButtonAfterPage is set to 'infinite', then the button will never be shown and the list will always autoload
  // ************************************************************************************
  useEffect(() => {
    const loadMoreElements = setTimeout(() => {
      if (autoloadInView && showLoadMore && (autoload !== 'infinite' ? refSize < autoload : true)) {
        void setSize((currentSize) => currentSize + 1);
      }
    }, 50);

    return () => clearTimeout(loadMoreElements);
  }, [autoloadInView, setSize, showLoadMore, refSize, autoload]);


  // Render component
  // *****************************************************
  return (
    <>
      { // Render the list
        !isEmpty && renderList()
      }

      { // Render the empty text / custom empty state if isEmpty is true
        isEmpty && (isEmptyTitle || isEmptyText || renderEmptyState) && !isLoading
          && (renderEmptyState ? renderEmptyState() : <NoResults isEmptyTitle={isEmptyTitle} isEmptyText={isEmptyText} />)
      }

      { /* Intersection observer autoload watcher; on first load 'showLoadMore' is undefined, we need to account for that */ }
      <div ref={autoloadRef}>
        {isLoading && !showButton && showLoadMore !== false && <div className="my-8 text-center"><Spinner /></div>}
      </div>

      { // Render the button if showButton is true
        showButton && renderButton()
      }
    </>
  );
};
