// Imports
// ***********************************************
import { getCookie, deleteCookie } from 'cookies-next';
import { flattenObj } from '../flatten-expand-object/flatten-expand-object';
import { safeParseJsonObject } from '../json-helpers/json-helpers';


/**
 * @description Get the form values from a JSON object saved in a cookie. It safely parses the json
 * and you have the option to delete the cookie afterward. Be careful to check for the cookie
 * existence though.
 * @param cookieName string
 * @param deleteAfter boolean
 */
export const getFormValuesFromCookie = (cookieName: string, deleteAfter = false) => {
  const value = flattenObj(safeParseJsonObject(getCookie(cookieName) as never));
  if (deleteAfter) void deleteCookie(cookieName);
  return value;
};
