import { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { ButtonProps } from '@type/anchors-buttons';
import { Button } from 'src/components/common/Forms/Button/Button';

type HamburgerButtonProps = ButtonProps & {
  menuOpened: boolean,
  asButton?: boolean,
};

type HamburgerButtonRef = HTMLButtonElement;


export const HamburgerButton = forwardRef<HamburgerButtonRef, HamburgerButtonProps>((props, ref) => {
  // Props with default values
  const {
    menuOpened,
    asButton = true,
    ...restProps
  } = props;

  // translate
  const { t } = useTranslation('common');

  // Bars classes
  const common = 'block bg-ink transition-all duration-300 ease-out h-0.5 w-5';
  const topBar = `${common} ${menuOpened ? 'rotate-45 translate-y-1' : '-translate-y-0.5'}`;
  const middleBar = `${common} ${menuOpened ? 'opacity-0' : 'opacity-100'} my-0.5`;
  const bottomBar = `${common} ${menuOpened ? '-rotate-45 -translate-y-1' : 'translate-y-0.5'}`;

  return (
    <>
      { // Render as button (default)
        asButton && (
          <Button ref={ref} styling="none" className="flex flex-col items-center justify-center" {...restProps}>
            <span className="sr-only">{t('sr.open-mobile-menu')}</span>
            <span aria-hidden="true" className={topBar} />
            <span aria-hidden="true" className={middleBar} />
            <span aria-hidden="true" className={bottomBar} />
          </Button>
        )
      }

      { // Render as span (use inside another button or anchor)
        !asButton && (
          <span ref={ref} className="flex flex-col items-center justify-center" {...restProps}>
            <span className="sr-only">{t('sr.open-mobile-menu')}</span>
            <span aria-hidden="true" className={topBar} />
            <span aria-hidden="true" className={middleBar} />
            <span aria-hidden="true" className={bottomBar} />
          </span>
        )
      }
    </>

  );
});
