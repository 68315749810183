import {
  Fragment,
  ReactNode,
  RefObject,
  ElementType,
  JSX, Ref,
} from 'react';
import {
  PopoverPanel,
  Transition,
  PopoverPanelProps,
  TransitionEvents,
} from '@headlessui/react';
import {
  composePopoverPanelClasses,
  composePopoverPanelAnimation,
} from './popoverStyles';


/** Types */
type PopoverStyledPanelProps = PopoverPanelProps<ElementType<JSX.Element>> & TransitionEvents & {
  children: ReactNode,
  className?: string,
  ref?: Ref<HTMLElement>,
  animation?: 'fade' | 'slideUp' | 'slideDown',
  position?: 'left' | 'center' | 'right' | 'anchored',
  ui?: 'none' | 'default' | 'anchored', // 'none' will reset all the classes from the component
  close?: (focusableElement?: (HTMLElement | RefObject<HTMLElement | null> | undefined)) => void,
};


/**
 * @description Customized PopoverPanel component. It has a default animation and UI styles.
 * - `animation` - Animation for the component. Default is 'fade'.
 * - `ui` - UI styles for the component. Default is 'default'. 'none' will reset all the classes from the component.
 * - `position` - Position of the component. Default is 'center'.
 * - `modal` - If true, the component will be rendered as a modal (no page scrolling when open).
 * - `anchor` - Use it when the parent has `overflow: hidden`.
 * - `className` - Any additional classes to add to the component.
 * - `ref` - Ref for the component.
 * - `close` - Function to close the popover.
 *
 * **Mount events:**
 * - `beforeEnter` - Function to be executed before the component enters.
 * - `afterEnter` - Function to be executed after the component enters.
 * - `beforeLeave` - Function to be executed before the component leaves.
 * - `afterLeave` - Function to be executed after the component leaves.
 *
 * @example
 * // When the parent has `overflow: hidden` use the `anchor` prop to set the position of the PopoverStyledPanel
 * // For floated elements, use the min-w-[var(--button-width)] to set the width of the dropdown same as the button
 * <PopoverStyledPanel
 *    animation="slideDown"
 *    anchor="bottom start"
 *    className="mt-1 min-w-[var(--button-width)]"
 * >
 *   ...
 * </PopoverStyledPanel>
 *
 * // When the parent has NO `overflow: hidden` use the `position` prop to set the position of the PopoverStyledPanel
 * <PopoverStyledPanel
 *   animation="slideDown"
 *   className="mt-1"
 *   position="right"
 * >
 *   ...
 * </PopoverStyledPanel>
 */
export const PopoverStyledPanel = (props: PopoverStyledPanelProps) => {
  const {
    children,
    className = '',
    animation = 'fade',
    position = 'center',
    ui = 'default',
    modal = false,
    anchor,
    beforeEnter,
    beforeLeave,
    afterEnter,
    afterLeave,
    ...rest
  } = props;

  // auto adjust for the anchor prop
  const adjustedPosition = anchor ? 'anchored' : position;
  const adjustedUi = anchor && ui !== 'none' ? 'anchored' : ui;

  // component classes
  const componentClasses = composePopoverPanelClasses(adjustedPosition, adjustedUi, className);

  // component animation
  const componentAnimation = composePopoverPanelAnimation(animation);

  // get the unmount prop
  const { unmount = true } = rest;


  // Render component
  // **************************
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom={componentAnimation.hiddenState}
      enterTo={componentAnimation.visibleState}
      leave="transition ease-in duration-150"
      leaveFrom={componentAnimation.visibleState}
      leaveTo={componentAnimation.hiddenState}
      unmount={unmount}
      {...beforeEnter && { beforeEnter }}
      {...afterEnter && { afterEnter }}
      {...beforeLeave && { beforeLeave }}
      {...afterLeave && { afterLeave }}
    >
      <PopoverPanel
        className={componentClasses}
        modal={modal}
        {...anchor && { anchor }}
        {...rest}
      >
        { children }
      </PopoverPanel>
    </Transition>
  );
};
