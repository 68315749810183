import { useState, useEffect, useRef } from 'react';
import { mergeRegister } from '@lexical/utils';
import { FOCUS_COMMAND, BLUR_COMMAND } from 'lexical';
import { LexicalEditorType } from '@type/lexical';

/**
 * @description Hook to get the validity of a Lexical editor.
 * - `editor` - the Lexical editor instance
 *
 * @returns an object with the following properties:
 * - `htmlContent` - the html content of the editor (as string); pass this to the form data.
 * - `setHtmlContent` - setter for the html content.
 * - `textContent` - the text content of the editor (as string); use this to check the editor's validity.
 * - `setTextContent` - setter for the text content.
 * - `isValid` - the validity of the editor; use this to show the error message in Lexical.
 * - `setIsValid` - setter for the validity.
 * - `hasFocus` - the focus state of the editor.
 * - `setHasFocus` - setter for the focus state.
 * - `checkLexicalValidity` - call this to check the editor's validity on form submission.
 * - `getLexicalContent` - get the html content of the editor.
 *
 * **Note:**
 * `htmlContent` for allowed tags and attributes check the `HtmlPlugin.tsx` file.
 *
 * @example
 * // This is needed in order to get the editor instance
 * const editorRef = useRef<LexicalEditorType>(null);
 *
 * // Use this hook
 * const {
 *  checkLexicalValidity, htmlContent, setHtmlContent, setTextContent, isValid,
 * } = useLexicalValidity(editorRef?.current);
 *
 * // BjForm hook
 * const {
 *  handleCallbackSubmit, record, checkFormValidation,
 * } = useBjForm({ wizardData: { description: htmlContent } });
 *
 * // If you make the editor required, you need to check its validity before submitting the form
 * // That means you can't just call handleCallbackSubmit(e, submitCallback) directly
 * const submitForm = (e: FormEvent<HTMLFormElement>) => {
 *  // This is a bit redundant (handleCallbackSubmit also checks form elements validity), but it's a way to verify both
 *  // the formData and the editor validity at the same time.
 *  e.preventDefault();
 *  checkFormValidation(e);
 *  checkLexicalValidity();
 *  // Go normally if the editor and form elements are valid
 *  return handleCallbackSubmit(e, submitCallback);
 * };
 *
 * // Inside render
 * <Lexical
 *  required
 *  handleHtmlDescription={setHtmlContent}
 *  handleTextDescription={setTextContent}
 *  autoFocus={false}
 *  ref={editorRef}
 *  isValid={isValid}
 * />
 */
export const useLexicalValidity = (editor: LexicalEditorType | null) => {
  // Helper states
  // *********************************
  const hasInteracted = useRef(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [textContent, setTextContent] = useState('');
  const [hasFocus, setHasFocus] = useState<boolean | undefined>(undefined);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  // Register the focus and blur commands
  // ********************************
  useEffect(() => {
    if (editor) {
      setHasFocus(editor.getRootElement() === document.activeElement);
      return mergeRegister(
        editor.registerCommand(
          FOCUS_COMMAND,
          () => {
            setHasFocus(true);
            return false;
          },
          1,
        ),
        editor.registerCommand(
          BLUR_COMMAND,
          () => {
            setHasFocus(false);
            if (!hasInteracted.current) {
              hasInteracted.current = true;
            }
            return false;
          },
          1,
        ),
      );
    }

    return undefined;
  }, [editor]);

  // Update the validity
  // Focus is initially undefined, this is the way to determine
  // if the user has interacted with the editor
  // Allows validation with images only
  // ********************************
  useEffect(() => {
    if (hasFocus !== undefined && hasInteracted.current) {
      if (textContent.length > 0 || htmlContent.includes('<img')) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [hasFocus, textContent, htmlContent]);

  // Check the validity of the Lexical editor
  // Also update the validity state
  // Allows validation with images only
  // ********************************
  const checkLexicalValidity = () => {
    hasInteracted.current = true;
    if (textContent.length > 0 || htmlContent.includes('<img')) {
      setIsValid(true);
      return true;
    }
    setIsValid(false);
    return false;
  };

  // Get the Lexical content
  // ********************************
  const getLexicalContent = () => htmlContent;

  // Return the states and setters
  // ********************************
  return {
    htmlContent,
    setHtmlContent,
    textContent,
    setTextContent,
    isValid,
    setIsValid,
    hasFocus,
    setHasFocus,
    checkLexicalValidity,
    getLexicalContent,
  };
};
