interface IProgressBarProps {
  percentage: number;
}

export const ProgressBar = ({ percentage }: IProgressBarProps) => {
  const progressBarWidth = {
    width: `${percentage}%`,
  };
  return (
    <>
      <div className="mb-6 h-2 w-full rounded-full bg-gray-200">
        <div className="h-2 rounded-full bg-secondary transition-all duration-500" style={progressBarWidth} />
      </div>
    </>
  );
};

