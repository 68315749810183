import { useTranslation } from 'next-i18next';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FormErrorProps } from 'src/types/form-types';

/**
 * @description Shows form field error
 * @remarks Uses headless ui animations
 * @param errorMessage
 * @param isShowing
 */

export const FormError = ({ errorMessage, isShowing }: FormErrorProps) => {
  const { t } = useTranslation('common');

  return (
    <Transition
      as={Fragment}
      appear
      show={isShowing}
      enter="transition-all duration-300"
      enterFrom="opacity-0 -translate-y-1.5"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1.5"
    >
      <span className="order-last mt-1.5 flex w-full items-start text-sm text-error">
        <span className="mr-1.5 mt-0.5 inline-block rounded bg-error px-1 pt-px align-middle text-pico uppercase leading-snug text-surface">
          {t('msg-error')}
        </span>
        {errorMessage}
      </span>
    </Transition>
  );
};
