import { ErrorInfo } from 'react';
import { useTranslation } from 'next-i18next';
import { Button } from 'src/components/common/Forms/Button/Button';


// Interface
// *******************************
interface ClientSideErrorPageProps {
  reset?: () => void,
  error?: Error,
  errorInfo?: ErrorInfo,
}

/**
 * @description Client side error page to be used with ErrorBoundary component.
 * Intended use: main app component like in Next.js
 * {@link https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors documentation}
 * @param props
 *
 * @example
 * <ErrorBoundary fallbackComponent={<ClientSideErrorPage />}>
 *   <App />
 * </ErrorBoundary>
 */
export const ClientSideErrorPage = (props: ClientSideErrorPageProps) => {
  // Destructure props
  const {
    reset, error, errorInfo,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Error info title
  const errorTitle = error ? `${error?.name}: ${error?.message}` : '';


  // Render component
  // *******************************
  return (
    <div className="flex grow items-center bg-surface-100">
      <div className="container mx-auto py-10">

        {/* Title & description */}
        <p className="text-sm font-semibold uppercase tracking-wide text-primary">{t('error.boundary.label.type')}</p>
        <h1 className="mt-2 text-6xl font-bold tracking-tight text-ink sm:text-7xl">{t('error.boundary.label.title')}</h1>
        <p className="mt-2 text-ink-medium">{t('error.boundary.label.description')}</p>

        {/* Error info */}
        <pre className="mt-5 rounded-md border bg-surface p-4 text-sm">
          <strong className="block text-error">{errorTitle}</strong>
          <span className="block whitespace-pre-wrap break-words pb-2 text-xs leading-none text-ink-medium">
            {errorInfo?.componentStack?.toString()}
          </span>
        </pre>

        {/* Action buttons */}
        <div className="mt-6 flex gap-2">
          <Button rounding="full" size="sm" color="secondary" onClick={reset}>{t('error.boundary.label.button.try-again')}</Button>
          <Button rounding="full" size="sm" styling="outline" color="ink-medium" onClick={() => window.history.back()}>{t('global.action.back')}</Button>
        </div>

      </div>
    </div>
  );
};
