import { Fragment, ReactNode } from 'react';
import { Tab as HeadlessTab } from '@headlessui/react';
import { Button } from 'src/components/common/Forms/Button/Button';
import { ButtonProps } from 'src/types/anchors-buttons';


/** Types */
// get the colors from the ButtonProps
type IButtonColors = ButtonProps['color'];
type IButtonStyling = ButtonProps['styling'];

interface TabStyledButtonProps extends ButtonProps {
  children?: ReactNode,
  stylingSelected?: IButtonStyling,
  stylingNotSelected?: IButtonStyling,
  colorSelected?: IButtonColors,
  colorNotSelected?: IButtonColors,
}


/**
 * @description Customized TabButton component. It has a default Button component that is customized.
 * You can use the btn-group custom classes to style the parent (TabStyled.List).
 *
 * * **stylingSelected** - the styling of the button when selected: text, outline, solid, none; default: solid
 * * **stylingNotSelected** - the styling of the button when not selected: text, outline, solid, none; default: outline
 * * **colorSelected** - the color of the button when selected; default: primary
 * * **colorNotSelected** - the color of the button when not selected; default: primary
 *
 * @example
 * <TabStyledList className="btn-group mb-4">
 *   <TabStyledButton rounding="none" size="sm">Tab 1</TabStyledButton>
 *   <TabStyledButton rounding="none" size="sm">Tab 2</TabStyledButton>
 *   <TabStyledButton rounding="none" size="sm">Tab 3</TabStyledButton>
 * </TabStyledList>
 */
export const TabStyledButton = (props: TabStyledButtonProps) => {
  const {
    children,
    stylingSelected = 'solid',
    stylingNotSelected = 'outline',
    colorSelected = 'primary',
    colorNotSelected = 'primary',
    ...restProps
  } = props;

  return (
    <HeadlessTab as={Fragment}>
      {({ selected }) => (
        <Button
          styling={selected ? stylingSelected : stylingNotSelected}
          color={selected ? colorSelected : colorNotSelected}
          {...restProps}
        >
          { children }
        </Button>
      )}
    </HeadlessTab>
  );
};
