/**
 * @description Renders the typing dots for the chat
 */
export const ChatTypingDots = () => {
  const baseDotClass = 'float-left w-2 h-2 bg-ink-medium opacity-0 rounded-full animate-loading-fade';

  return (
    <div className="relative w-fit rounded-3xl bg-surface-100 p-2">
      <div className={`${baseDotClass} mr-1`} />
      <div className={`${baseDotClass} mr-1 animate-delay-200`} />
      <div className={`${baseDotClass} animate-delay-400`} />
    </div>
  );
};
