import { DialogTitle } from '@headlessui/react';
import { Button } from 'src/components/common/Forms/Button/Button';
import { ModalCommonProps } from 'src/types/modal-types';
import { ModalStyles } from './_modalStyles';


/**
 * @description Modal header component.
 * * **className** - additional classes for the header
 * * **content** - header content when not using children (passed as prop)
 * * **onClose** - function to close the modal
 * * **children** - header content when using the header with dot notation. It will display nothing
 * if you pass the content prop.
 */
export const ModalHeader = (props: ModalCommonProps) => {
  // Destructuring props
  const {
    content = '',
    className = '',
    onClose,
    children,
  } = props;

  // Header content; can be either from content prop or children, but not both
  const headerContent = content || children;

  // Render component
  // **************************************
  return (
    <DialogTitle as="div" className={`${ModalStyles.header} ${className}`}>

      {/* Header content */}
      {headerContent}

      {/* Close button */}
      <Button color="light" size="sm" className="-mr-2 ml-3 !rounded-full !p-2" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 19.5l15-15m-15 0l15 15" />
        </svg>
      </Button>

    </DialogTitle>
  );
};
