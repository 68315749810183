import { fetcher, ResponseWithHeaders, isApiV1Errors } from '@utils/data-fetching';
import {
  ChangeEvent, FormEvent, RefObject, useCallback, useEffect, useState,
} from 'react';
import {
  ConvertFrom,
  convertValue,
  convertEmptyValue,
  expandObj,
  trimStringEmptySpaces,
} from '@utils/index';
import {
  SuccessResponseData,
  BjFormOptionsInterface,
  FormDataInterface,
  FormStatusInterface,
  PostFormDataInterface,
  ServerFieldErrorInterface,
  ValidFormElementsInterface,
  FormElementTypes,
  HandleFieldSubmitInterface,
  FormElementRefImperative,
  ProcessFormFieldValueInterface,
  FiltersObjectInterface,
  GlobalValuesTypes,
  StoredElementsInterface,
  CallbackSubmitFunction,
} from 'src/types/form-types';
import { useRouterLocale } from '@hooks/common/useRouterLocale';
import { useRecordForm } from './useFormRecord';
import { useGetFormValues } from './useGetFormValues';
import { useHandleFormErrors } from './useHandleFormErrors';
import { useStaticToasts } from '../useStaticToasts';


// BJ FORM HOOK *******************************************************
/**
 * @description Main Bj Custom Form module. The config options are:
 *
 * * **submitURL** - the url where the form will be submitted, when used with the {@link handleSubmit} function; we're using the fetcher,
 * so the URL should be the one from the fetcher, without domain; default: ''
 * * **submitMethod** - the submit method; default 'POST'
 * * **withDefaultValues** - if we have default values for form elements; used for Edit cases (ie: edit experience); values: null | object | array: default: null
 * * **wizardData** - if we have a wizard and this is the last step; values: null | object; default: null
 * * **globalEmptyValues** - how we globally treat empty values; per field values take priority; toNull' | 'remove' | 'keep'. default: keep
 * * **trimWhiteSpaces** - prevent sending a string full of empty spaces and the server to fail silently; this is a fix for API;
 * passes the form values through the {@link trimStringEmptySpaces} utility function; default: true
 * * **convertOldApiErrors** - if we want to convert the old API (v0) errors object to the new format (v1) and show the errors;
 * passes the error object through {@link convertOldApiFormErrors} function; default: false
 * * **scrollToFirstError** - scroll to first error onSubmit (both client & server side errors)
 * * **showToasts** - if we want to show toasts; we're using the 'showStatusToast' function; to show toasts:
 * 1. either set it to 'true' to show the toasts for all status codes
 * 2. either pass an object with the 'showStatusToast' function parameters (minus status code),
 * like this: {
 *   customMessages: {message_201: response.message, message_403: 'You can send only 10 emails/day.'},
 *   ignoreStatus: ['401'],
 * }
 * * **withFilterLabels** - if we want to get the labels for the valid fields when we're using the form for making filters. The object with the labels is stored in the
 * state and can be accessed via the 'formStatus' state (formStatus.filterLabels). For each 'name' in the form, we get the labels as an array
 * of strings (ie: {myField: ['label1', 'label2']}). The labels can be added as follows:
 * 1. static, directly in the 'record' function, like this: record('myField', {filtersLabel: 'label1'}); used most multiple groups: radio, checkbox and select;
 * 2. dynamic, using the 'data-filters-label' attribute; use it when you want to pass a variable/state value as label;
 * 3. directly from value, if none of the above is used, the value will be used as label (ie: input text fields);
 * Most importantly, don't forget to transform the labels arrays using the mergeFilterValues() function;
 *
 * ---------------------------
 *
 * @description **Returned functions:**
 *
 * * {@link handleStep} - Helper function that handles a form step submit (does not post)
 * * {@link handleSubmit} - Helper function that handles the form submit. Takes the gathered form data and posts it.
 * * {@link handleCallbackSubmit} - Handles the form submit with a callback function (ie: 'trigger' from SWR). Takes the
 * gathered form data and executes the callback function with it.
 * * {@link handleCallbackErrors} - Helper function that handles the errors of a form submit via the handleCallbackSubmit.
 * * {@link handleFieldSubmit} - Helper function that handles a form field submit (will submit that field only).
 * * **record** - Helper function that builds an object to be spread as element's props. A core function of the BjForm
 * Library. ({@link useRecordForm} hook)
 * * {@link checkFormValidation} - Helper function that does the front-end validation using the HTML5 API. It handles the form errors
 * for the client-side. Internally is calling methods stored in **useSingleFormElement** & **useCheckValidity** hooks via **elementsRefs**
 * * {@link useHandleFormErrors#handleFormErrors} - Helper function that handles form errors from server using the
 * V1 API ({@link useHandleFormErrors} hook).
 * * {@link useHandleFormErrors#convertOldApiFormErrors} - Helper function that transforms the old API object error to
 * the V1 format ({@link useHandleFormErrors} hook).
 * * {@link postFormData} - Helper function that posts form data using the fetcher function.
 * * {@link setValuesFromAPI} - Helper function used to populate the form fields with values. It uses
 * * {@link formStatus} - State that stores the current state of the form after submit (use it to check for post success or fail, get
 * submitted form values, etc.)
 * * {@link useRecordForm#storedElements} - State that holds a record with all the 'recorded' form fields
 * ({@link useRecordForm} hook)
 * * {@link useRecordForm#elementsRefs} - State that holds a record of refs as objects using field's id as key. Used to
 * be able to access internal
 * methods of form Components ({@link useRecordForm} hook).
 * * {@link useGetFormValues#getValuesFromAPI} - API call function that uses the fetcher function for getting form's default values
 * on the client side ({@link useGetFormValues} hook).
 * * {@link useGetFormValues#formValues} - State that holds the form default values obtained via the **getValuesFromAPI** helper function. Changing this triggers the
 * {@link setValuesFromAPI} function and the form values are updated.
 * * {@link useGetFormValues#setFormValues} - Setter for **formValues** state. Can also be used to manually add/modify form current values (this changes **formValues**
 * which triggers {@link setValuesFromAPI}).
 * {@link useGetFormValues#setFlatFormValues} - Function that flattens an object before passing it to *setFormValues*.
 * **getValuesStatus** - State that stores the current state of the **getValuesFromAPI** (getting the client side data for form)
 * * {@link getFieldValue} - Helper function that gets a form element's value. This is also useful if you need to check if a radio or checkbox is checked.
 * * {@link getCheckedStatus} - Helper function that gets a form element's checked status. This is useful if you need to check if a radio or checkbox is checked.
 * * {@link resetForm} - Helper function that resets the form and clears all the states for all fields if form is invalid.
 * * {@link hardResetForm} - Helper function that goes beyond the **resetForm** function and resets the default values too. Use it carefully.
 * * {@link submitTrigger} - Triggers the submit event for form and also executes the function attached to form submit event (ie: onSubmit={handleSubmit})
 * * {@link updateGroupValidity} - Updates the validity for required radio form groups.
 * * {@link isPosting} - State for fetching status when we're submitting the form.
 * * {@link isFetchingFormData} - State for fetching status when we're getting the form data.
 * * **updateFormElements** - Function that force the update of the form elements (use it when you want to add new elements to the form after the initial render, ie: Dropdown panel mounting).
 * * {@link selectAllCheckboxes} - Function that selects/deselects all checkboxes in a group.
 * * {@link getFormData} - Helper function that returns the form data. It uses the *shapeFormValues* function to shape the form values.
 *
 * ---------------------------
 *
 * @example
 * // required
 * import { useBjForm } from '@hooks/useBjForm';
 *
 * // basic usage
 * const { handleSubmit, record } = useBjForm({ submitURL: thePostURL });
 *
 * // without 'noValidate' the form will not run our functions
 * <form noValidate onSubmit={handleSubmit}>
 *    <Input type='text' {...record('userName')} />
 *    <Input type='password' {...record('password')} />
 *    <Button type='submit'>Submit</Button>
 * </form>
 *
 * // if you want to also pass another function on submit you can use
 * <form noValidate onSubmit={(e) => {handleSubmit(e); yourOtherFunction();}}>
 *
 */
export const useBjForm = (options: BjFormOptionsInterface = {}) => {
  // BJ Form options
  const {
    // the URL where you submit the form
    submitURL = '',
    // the submit method
    submitMethod = 'POST',
    // if we have default values for form elements; used for Edit cases (ie: edit experience); null | object | array
    // object: directly supply the values (dev mode); or get them inside getServerSideProps() - best option
    // array: get them on the client side;
    // array options: [{
    //    reqURL: string (the getURL),
    //    dataKey: string (sometimes the values are inside a key of response object, ie 'data', 'schema')
    // }]
    withDefaultValues = null,
    // if we have a wizard and this is the last step; null | object
    wizardData = null,
    // how to globally treat optional empty values: 'toNull' | 'nullable' | 'keep';
    // we don't manipulate elements that have the 'convertValue' or 'convertEmptyValue' recorded options;
    // 'nullable' - default - delete all empty key/value pairs from object;
    // 'toNull' - makes all empty values null;
    // 'keep' - do not change anything;
    globalEmptyValues = 'nullable',
    // prevent sending a string full of empty spaces and the server to fail silently (no errors on title: '                ')
    trimWhiteSpaces = true,
    // if we want to convert the old API errors object to the new format and show them
    convertOldApiErrors = false,
    // scroll to first error onSubmit (both client & server side errors)
    scrollToFirstError = true,
    // if we want to show toasts; we're using the 'showStatusToast' function; to show toasts:
    // 1. either set it to 'true' to show the toasts for all status codes
    // 2. either pass an object with the 'showStatusToast' function parameters (minus status code),
    // like this: {
    //    customMessages: {message_201: response.message, message_403: 'You can send only 10 emails/day.'},
    //    ignoreStatus: ['401'],
    // }
    showToasts = false,
    // If we want to get the labels for the valid fields when we're using the form for making filters. The object with the labels is stored in the
    // state and can be accessed via the 'formStatus' state (formStatus.filterLabels). For each 'name' in the form, we get the labels as an array
    // of strings (ie: {myField: ['label1', 'label2']}). The labels can be added as follows:
    // 1. static, directly in the 'record' function, like this: record('myField', {filtersLabel: 'label1'}); used most multiple groups: radio, checkbox and select;
    // 2. dynamic, using the 'data-filters-label' attribute; use it when you want to pass a variable/state value as label;
    // 3. directly from value, if none of the above is used, the value will be used as label (ie: input text fields);
    withFilterLabels = false,
  } = options;

  // get current lang
  const locale = useRouterLocale();

  // use getFormValues custom hook
  const {
    getValuesFromAPI, storedDefaultValues, formValues, setFormValues, setFlatFormValues, getValuesStatus, isFetchingFormData,
  } = useGetFormValues(locale, withDefaultValues);

  // use RecordForm custom hook
  const {
    record, storedElements, elementsRefs, updateFormElements,
  } = useRecordForm(storedDefaultValues);

  // form status
  const [formStatus, setFormStatus] = useState<FormStatusInterface>({});

  // use Form Errors custom hook
  const { convertOldApiFormErrors, handleFormErrors } = useHandleFormErrors();

  // State for fetching status when we're submitting the form
  const [isPosting, setIsPosting] = useState(false);

  // Status toasts
  const { showStatusToast } = useStaticToasts();


  /**
   * @description Populate the form fields with values. Form values are obtained in this case
   * via the {@link useGetFormValues} custom hook
   *
   * @remarks: Dependencies: {@link storedElements}, {@link formValues}, {@link elementsRefs};
   */
  const setValuesFromAPI = useCallback(() => {
    storedElements.forEach((element) => {
      const nameKey: string = element.name;
      const refKey: string = element.id;

      // element refs are stored by id
      const currElement = elementsRefs[refKey]?.current;
      // values in forms are always by name (for instance radio groups)
      const newValue = formValues?.[nameKey as keyof typeof formValues];

      // guard against normal form elements that are null and don't have the required methods
      // add the value to the element
      if (newValue !== undefined && currElement !== undefined && currElement !== null && Object.hasOwn(currElement, 'setElementValue')) {
        // we need to check for the situations when we have a multiple element but the value is not an array, in order to
        // convert it to an array (ie: select multiple, checkbox multiple). Consider the case when we have a single value
        // from URLSearchParams (ie: 'value=1') and we need to convert it to an array (ie: 'value=[1]'), because the
        // element is 'multiple' and we need to pass the value as an array.
        if (Object.hasOwn(element, 'valueAsArray') && !Array.isArray(newValue)) {
          currElement.setElementValue([newValue] as never);
        } else {
          currElement.setElementValue(newValue);
        }
      }
    });
  }, [storedElements, formValues, elementsRefs]);


  /**
   * Display the form's values but check if we've got them (not an empty object),
   * otherwise you will get a flash of undefined as fields values
   */
  useEffect(() => {
    if (Object.keys(formValues).length !== 0) {
      setValuesFromAPI();
    }
  }, [formValues, setValuesFromAPI]);


  /**
   * @description Helper function: find duplicates inside an array
   * @description We compare the index of all the items of an array with the index of the first time that number occurs.
   * If they don’t match, that means that the element is a duplicate.
   * @param array to check for duplicates
   * @returns the duplicates (as array)
   */
  const findArrayDuplicates = (array: string[]) => array.filter((item, index) => array.indexOf(item) !== index);


  /**
   * @description Helper function to process a form's field value
   *
   * * We trim multiple consecutive spaces using {@link trimStringEmptySpaces} function
   * if the useBjForm {@link trimWhiteSpaces} option is set to true (default true)
   * * We shape each individual field based on the recorded 'convertValue' and 'convertEmptyValue' options (can use both)
   * If the element is 'multiple', we put the values inside an array (getAll()). We also check if we need to convert empty field value.
   *
   * @param element - element of **storedElements**
   * @param formData - the **formData** object
   * @param elementsWithMultiple - string array of form elements with **multiple** attribute
   */
  const processFormFieldValue: ProcessFormFieldValueInterface = useCallback((element, formData, elementsWithMultiple) => {
    // check if this instance of form element is of 'multiple' type
    let isMultiple = false;
    elementsWithMultiple.forEach((el) => {
      if (el === element.name) isMultiple = true;
    });

    // store the form element's value accordingly
    let elementValue = (isMultiple || Object.hasOwn(element, 'valueAsArray'))
      ? formData.getAll(element.name)
      : trimStringEmptySpaces(formData.get(element.name), trimWhiteSpaces);

    // check if we need to convert the field value, if not return the value
    // (ie: declared when recording field with 'convertValue': 'toNumber')
    if (Object.hasOwn(element, 'convertValue')) {
      // if element is array (for multiple)
      if (Array.isArray(elementValue)) {
        elementValue = elementValue.map((elem: ConvertFrom) => convertValue(element.convertValue, elem));
      } else { // normal element
        elementValue = convertValue(element.convertValue, elementValue);
      }
    }

    // check if we need to convert empty field value, if not return the value
    // (ie: declared when recording field with 'convertEmptyValue': 'toString')
    if (Object.hasOwn(element, 'convertEmptyValue')) {
      // if element is array (for multiple) and we don't have values
      if (Array.isArray(elementValue) && !elementValue.length) {
        elementValue = convertEmptyValue(element.convertEmptyValue, '');
      } else { // normal element
        elementValue = convertEmptyValue(element.convertEmptyValue, elementValue);
      }
    }

    return [element.name, elementValue];
  }, [trimWhiteSpaces]);


  /**
   * @description We take care of building a form values object.
   *
   * Requires:
   * * {@link convertEmptyValue} utility function
   * * {@link findArrayDuplicates} utility function
   * * {@link processFormFieldValue} helper function
   * @param formData
   */
  const getValidFormValues = useCallback((formData: FormData): ValidFormElementsInterface => {
    // We need to detect if a form element is of multiple type - input file, select multiple, checkboxes with the same name, etc.
    // We spread the formData.entries() into a 2 dimensional array [...formData] (equivalent to [...formData.entries()]),
    // then extract the first element of each child array, because for each selected value the name will be the same.
    // We then pass this as argument to the findArrayDuplicates helper function.
    // Based on this we will be able to use formData.getAll() for 'multiples'.
    const elementsWithMultiple: string[] = findArrayDuplicates([...formData.entries()].map((elem) => elem[0]));

    // get the valid form values
    return Object.fromEntries(storedElements.map((element) => processFormFieldValue(element, formData, elementsWithMultiple)));
  }, [processFormFieldValue, storedElements]);


  /**
   * @description Shape the valid form values
   *
   * * Takes the formData object and expands it using the {@link expandObj} function
   * * We delete the formData key/value pairs for the fields with **nullable** option set to true. If true
   * we also delete / convert 'toNull' the fields passed within the **removeFieldsIfThisEmpty** array from field options
   * * We shape the empty fields globally, based on the {@link globalEmptyValues} option. We don't
   * shape fields that have the recorded 'convertEmptyValue' option.
   * 'toNull' - convert all empty values to null
   * 'nullable' - we delete all empty key/value pairs from object (Open API style)
   * 'keep' - default - we leave values as they are
   *
   * @param formData - *object* - the form data (got from {@link checkFormValidation})
   *
   * @returns expanded form object
   * @remarks Requires {@link convertEmptyValue} utility function
   */
  const shapeFormValues = useCallback((formData: FormData): object => {
    const validFormValues = getValidFormValues(formData);

    // shape the empty form values by removing / converting to null the empty elements (depending on options)
    storedElements.forEach((element) => {
      const hasValue = convertEmptyValue('toBoolean', validFormValues[element.name]);

      // if we have the option to delete the field (nullable in 'record' options)
      if (Object.hasOwn(element, 'nullable') && element.nullable && !hasValue) {
        delete validFormValues[element.name];

        // delete the 'dependent' fields (the ones in the array) if option available
        if (Object.hasOwn(element, 'removeFieldsIfThisEmpty') && element.removeFieldsIfThisEmpty) {
          element.removeFieldsIfThisEmpty.forEach((dependentField) => {
            delete validFormValues[dependentField];
          });
        }
      }

      // convert to null the 'dependent' fields (the ones in the array) if option available
      if (Object.hasOwn(element, 'removeFieldsIfThisEmpty') && element.removeFieldsIfThisEmpty && !hasValue) {
        element.removeFieldsIfThisEmpty.forEach((dependentField) => {
          validFormValues[dependentField] = null;
        });
      }

      // convert to null the 'dependent' fields if option available and element with the option is not checked
      if (Object.hasOwn(element, 'removeFieldsIfThisUnchecked') && element.removeFieldsIfThisUnchecked) {
        const refKey:string = element.id;
        const currElement = elementsRefs[refKey]?.current;
        const isChecked = currElement?.getCheckedValue();

        if (!isChecked) {
          element.removeFieldsIfThisUnchecked.forEach((dependentField) => {
            validFormValues[dependentField] = null;
          });
        }
      }

      // 1. we exclude elements that have 'record' option preferences on field for conversion
      // the 'globalEmptyValues: nullable' case (totally remove key value pairs)
      // 2. we delete the elements that have the 'removeFromSubmit' option true
      if ((!Object.hasOwn(element, 'convertEmptyValue') && globalEmptyValues === 'nullable' && !hasValue)
        || (Object.hasOwn(element, 'removeFromSubmit') && element.removeFromSubmit)) {
        delete validFormValues[element.name];
      }

      // the 'globalEmptyValues: toNull' case (convert the values to hard 'null')
      if (!Object.hasOwn(element, 'convertEmptyValue') && globalEmptyValues === 'toNull' && !hasValue) {
        validFormValues[element.name] = null;
      }
    });

    return expandObj(validFormValues);
  }, [elementsRefs, storedElements, globalEmptyValues, getValidFormValues]);


  /**
   * @description Get a form element's value. This is also useful if you need to check if a radio or checkbox is checked.
   * It also gets the data for multiple groups (checkbox multiple, select multiple).
   *
   * @param name - the name of the field
   * @param asObject - if we want the value as an formData object, like { name: value }; default false.
   *
   * @example
   * // input example for reference, let's assume the value as 'Bestjobs'
   * <Input type="text" {...record('company.name')} />
   *
   * // get the field value
   * getFieldValue('company.name');
   * // => 'Bestjobs'
   *
   * // get the field value as formData object
   * getFieldValue('company.name', true);
   * // => {'company.name': 'Bestjobs'}
   *
   * // using to determine if a radio or checkbox is checked
   * <Input type="radio" {...record('radioGroup', { id: 'radioBanana', defaultValue: 'banana' })} />
   * <Input type="radio" {...record('radioGroup', { id: 'radioApple', defaultValue: 'apple' })} />
   *
   * if (getFieldValue('radioGroup') === 'banana') // check if 'banana is checked'
   */
  const getFieldValue = useCallback((name: string, asObject = false) => {
    // Early return if stored elements is not initialized. Need this to prevent crashes.
    if (!storedElements.length) return '';

    // extract the element from storedElements: [{storedElement}]
    const storedElement = storedElements.filter((element) => ((name === element.name)));
    // get the id, so we will be able to trigger methods with ref. it does not matter for multiples, can be any of them
    const { id } = storedElement[0];

    // get a parent form and generate new FormData, so we can extract the value
    const parentForm = (elementsRefs[id]?.current as FormElementRefImperative).getElement().form as HTMLFormElement;
    const formData: FormData = new FormData(parentForm);

    // We need to detect if a form element is of multiple type - input file, select multiple, checkboxes with the same name, etc.
    // if multiple and more than one value, it will be returned as array;
    // it will always be an array if multiple and 'asArray' record option is true
    const elementsWithMultiple: string[] = findArrayDuplicates([...formData.entries()].map((elem) => elem[0]));

    // now get the value only for this entry
    const elementEntries = Object.fromEntries(storedElement.map((element) => processFormFieldValue(element, formData, elementsWithMultiple)));

    return asObject ? elementEntries : elementEntries[name];
  }, [storedElements, elementsRefs, processFormFieldValue]);


  /**
   * @description Get a form element's checked status. This is useful if you need to check if a radio or checkbox is checked.
   * If the element is not a radio or checkbox it will return undefined.
   * @param id - the id of the field
   */
  const getCheckedStatus = (id: string) => {
    if (!elementsRefs || !elementsRefs[id]?.current) return undefined;
    return elementsRefs[id]?.current?.getCheckedValue();
  };


  /**
   * @description CHECK VALIDATION
   * @description Function that checks if the form is valid.
   * @description If form is INVALID it looks through the form's elements and triggers {@link setCheckInvalid}
   * method on invalid children and then returns `{false}`.
   * @description If the form is VALID it returns an object with expanded formData using the {@link shapeFormValues} function.
   *
   * @param event - submit the form
   *
   * @returns false | formData
   *
   * @example
   * if (checkFormValidation(event)) {
   * // logs object with expanded formData
   *    console.log(checkFormValidation(event));
   * } else {
   *    console.log('custom submit form invalid');
   * }
   */
  const checkFormValidation = (event: FormEvent<HTMLFormElement> | RefObject<HTMLFormElement | null>): boolean | object => {
    const form = 'current' in event ? event.current as HTMLFormElement : event.target as HTMLFormElement;

    // Access the form's values: formData.get(field)
    const formData: FormData = new FormData(form);

    // Check if form will not validate
    // then check for invalid elements and mark them using their own methods
    // remember that all the element's info, including the error messages are stored inside storedElements(element)
    if (!form.checkValidity()) {
      const errorFields: string[] = [];

      storedElements.forEach((element) => {
        const refKey:string = element.id;
        const currElement = elementsRefs[refKey]?.current;

        // check if field is valid; guard against normal form elements that are null and don't have the required methods
        if (currElement !== undefined && currElement !== null && Object.hasOwn(currElement, 'setCheckInvalid')) {
          currElement.setCheckInvalid();
        }

        // build an array with the error fields; array keeps the fields order
        if (currElement !== undefined && currElement !== null && Object.hasOwn(currElement, 'checkElementValidity')) {
          if (currElement.checkElementValidity()) errorFields.push(currElement.checkElementValidity());
        }
      });

      // scroll to first error if useBjForm option is true
      if (errorFields.length && scrollToFirstError) {
        const elem = elementsRefs[errorFields[0]]?.current;
        if (elem !== undefined && elem !== null && Object.hasOwn(elem, 'scrollToElement')) {
          elem.scrollToElement();
        }
      }

      return false;
    }

    return shapeFormValues(formData);
  };


  /**
   * @description Get the labels for the valid fields when we're using the form for filters. It's useful for the filters
   * to always pass the field values as labels, the user can't understand what 'de_1', or 0_0 means. The labels are always
   * stored into an object with the key as the field name and the value as an array of labels. Keep in mind that the
   * 'filterLabels' key is not updating, so if you need to pass dynamic labels, you need to use the 'data-filters-label' attribute.
   * @param formData - the form data
   */
  const getLabelsForFilters = (formData: boolean | object) => {
    // skip if formData is boolean
    if (typeof formData === 'boolean') return {};

    // helper variables
    const filtersLabels: FiltersObjectInterface = {};
    const formDataEntries = Object.entries(formData);

    // helper functions to get the labels from data-attributes when we're dealing with variable or complex fields
    const getFromDataAttribute = (id: string) => elementsRefs[id]?.current?.getElement()?.getAttribute('data-filters-label');
    const getLabelString = (el: StoredElementsInterface) => getFromDataAttribute(el.id) || el.filtersLabel;

    // for each field in the form, we get the labels for the values
    formDataEntries.forEach((entry: [string, GlobalValuesTypes]) => {
      const [key, value] = entry;
      const labelsPartial: string[] = [];

      // find the fields that match the key
      const matches = storedElements.filter((storedEl) => storedEl.name === key);

      // fill the labelsPartial array with the labels
      if (Array.isArray(value)) {
        // 1. inputs with convertValue: 'commaStringToArray'
        // 2. checkbox multiple, select multiple case
        value.forEach((val) => {
          matches.forEach((match) => {
            if (match.convertValue === 'commaStringToArray') {
              if (!labelsPartial.includes(getLabelString(match) || String(val))) labelsPartial.push(getLabelString(match) || String(val));
            } else if (match.defaultValue === val) {
              labelsPartial.push(getLabelString(match) || String(val));
            }
          });
        });
      } else if (!Array.isArray(value) && matches.length > 1) {
        // the radio group case
        matches.forEach((match) => {
          if (match.defaultValue === value) {
            labelsPartial.push(getLabelString(match) || String(value));
          }
        });
      } else {
        // the rest of the cases
        matches.forEach((match) => labelsPartial.push(getLabelString(match) || String(value)));
      }

      // add the result to the filtersLabels object
      filtersLabels[key] = labelsPartial;
    });

    return filtersLabels;
  };


  /**
   * @description Helper function that returns the form data. It uses the {@link shapeFormValues} function to shape the form values.
   * - **target**: ref to the form
   * - **addWizardData**: if we want to add the wizard data to the form data
   * @returns object  - the form data
   *
   * @example
   * // create a ref for form
   * const formRef = useRef<HTMLFormElement>(null);
   * getFormData(formRef);
   */
  const getFormData = useCallback((target: RefObject<HTMLFormElement | null>, addWizardData = false): object => {
    const form = target.current as HTMLFormElement;
    const formData: FormData = new FormData(form);
    const shapeValues = shapeFormValues(formData);
    return addWizardData && wizardData ? { ...shapeValues, ...wizardData } : shapeValues;
  }, [shapeFormValues, wizardData]);


  /**
   * @description Helper function that checks or unchecks all the checkboxes in a form group.
   * @param isChecked - boolean - if we want to check or uncheck the checkboxes
   * @param groupName - string[] - the groups of checkboxes to check or uncheck
   *
   * @example
   * const [selectAll, setSelectAll] = useState(false);
   * selectAllCheckboxes(selectAll, ['groupName1', 'groupName2']);
   */
  const selectAllCheckboxes = useCallback((isChecked: boolean, groupName: string[]) => {
    storedElements.forEach((element) => {
      const refKey:string = element.id;
      const currElement = elementsRefs[refKey]?.current;
      const elementMatches = groupName.includes(element.name);
      if (elementMatches) currElement?.setCheckboxChecked(isChecked);
    });
  }, [storedElements, elementsRefs]);


  /**
   * @description Resets the form and clears all the states for all fields if form is invalid. It uses the default
   * form reset method, so it doesn't clear the default values.
   * @remarks Does not clear default values.
   * @param target
   *
   * @example
   * // create a ref for form
   * const formRef = useRef<HTMLFormElement>(null);
   *
   * // pass the ref to the form
   * <form ref={formRef}...
   *
   * // create a function and call it when and how you want it
   * const resetFormAction = (): void => {
   *   resetForm(formRef);
   * };
   */
  const resetForm = useCallback((target: RefObject<HTMLFormElement | null>): void => {
    const form = target.current as HTMLFormElement;

    if (!form.checkValidity()) {
      storedElements.forEach((element) => {
        const refKey:string = element.id;
        const currElement = elementsRefs[refKey]?.current;
        // guard against normal form elements that are null and don't have the required methods
        if (currElement !== undefined && currElement !== null && Object.hasOwn(currElement, 'resetField')) currElement.resetField();
      });
    }

    form.reset();
  }, [storedElements, elementsRefs]);


  /**
   * @description Hard reset the form and clears all the states for all fields if form is invalid. This function also
   * clears the default values, so use it only when you really, really want to clear the form completely.
   *
   * @example
   * // create a ref for form
   * const formRef = useRef<HTMLFormElement>(null);
   *
   * // pass the ref to the form
   * <form ref={formRef}...
   *
   * // create a function and call it when and how you want it
   * const hardResetFormAction = (): void => {
   *   hardResetForm(formRef);
   * };
   */
  const hardResetForm = useCallback((target: RefObject<HTMLFormElement | null>): void => {
    resetForm(target);

    // get all the form elements that are not marked as 'dontHardReset'
    const uniqueNameFields = new Set(storedElements
      .filter((el) => el.dontHardReset !== true)
      .map((el) => el.name));

    // build an object with the keys as the name of the field and the value as empty string
    const resetValues = Object.fromEntries(Array.from(uniqueNameFields).map((name) => [name, '']));

    // set the form values to empty
    setFormValues(resetValues);
  }, [resetForm, storedElements, setFormValues]);


  /**
   * @description Updates the validity for required radio form groups.
   *
   * * We show the error only on the last element of the group ( withError={false} ).
   * * We use the function on all the other elements of the group ( onChange={updateGroupValidity} ).
   *
   * @remarks This is a temporary solution.
   * @param event
   *
   * @example
   * <Input required withError={false} type="radio" onChange={updateGroupValidity} {...record('radioGroup', { id: 'radioGroup1', defaultValue: 'apple' })} />
   * <Input required withError={false} type="radio" onChange={updateGroupValidity} {...record('radioGroup', { id: 'radioGroup2', defaultValue: 'banana' })} />
   * <Input required type="radio" {...record('testInput10', { id: 'radioGroup', defaultValue: 'cherry' })} />
   */
  const updateGroupValidity = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const { name, type } = event.target;

    if (type === 'radio') {
      setFormValues(getFieldValue(name, true) as object);
    }
  }, [setFormValues, getFieldValue]);


  /**
   * @description Triggers the submit event for form and also executes the function attached to
   * form submit event (ie: onSubmit={handleSubmit})
   *
   * @param target
   *
   * @example
   * // create a ref for form
   * const formRef = useRef<HTMLFormElement>(null);
   *
   * // pass the ref to the form
   * <form ref={formRef}...
   *
   * // create a function and call it when and how you want it
   * const submitProgrammatically = (): void => {
   *   submitTrigger(formRef);
   * };
   */
  const submitTrigger = useCallback((target: RefObject<HTMLFormElement | null>): void => {
    const form = target.current as HTMLFormElement;

    form.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true }),
    );
  }, []);


  /**
   * @description Post form data using the fetcher function.
   * @description Handle form errors returned from server.
   * @description Save the status in a state ({@link formStatus} default).
   * @param URL
   * @param formData
   * @param method
   * @param actionStatusExtra
   * @param lang
   * @param requestHeaders
   */
  const postFormData: PostFormDataInterface = (URL, formData, method = 'POST', actionStatusExtra = '', lang = locale, requestHeaders = undefined) => {
    // set the filter labels
    const filterLabels = withFilterLabels ? getLabelsForFilters(formData) : {};

    // set the posting status
    setIsPosting(true);

    void fetcher(URL, lang, {
      withResponseHeaders: true,
      method,
      ...(formData ? { payload: formData, stringifyPayload: true } : {}),
      ...(requestHeaders ? { headers: requestHeaders } : {}),
    }).then((res) => {
      // set the posting status
      setIsPosting(false);

      // Destructure the response
      const {
        response, status, ok, statusText,
      } = res as ResponseWithHeaders<unknown>;

      // Handle success response
      if (ok) {
        setFormStatus({
          actionStatus: `success${actionStatusExtra}`,
          form: formData,
          filterLabels,
          data: response as SuccessResponseData,
          status,
          statusText,
        });

        // Show status toasts: object or boolean true
        if (showToasts) {
          if (typeof showToasts === 'object') {
            showStatusToast(status || '', showToasts.customMessages, showToasts.ignoreStatus);
          } else {
            showStatusToast(status || '');
          }
        }
      } else {
        // check if errors exist, don't assume they do
        const errors = isApiV1Errors(response) ? response.errors as ServerFieldErrorInterface[] : undefined;
        const checkErrors = errors && Array.isArray(errors) && errors?.length > 0;
        const hasError = checkErrors && typeof errors[0] === 'object' && errors[0] !== null;

        // Handle error response
        setFormStatus({
          actionStatus: `error${actionStatusExtra}`,
          form: formData,
          filterLabels,
          data: response as FormDataInterface,
          status,
          statusText,
        });

        // Handle form errors if invalid data sent
        if (status === 400 && hasError) {
          // 1. convert old API errors and show them;
          // 2. or show the new API errors
          if (convertOldApiErrors) {
            const convertedOldApiErrors = convertOldApiFormErrors(errors);
            handleFormErrors(convertedOldApiErrors, storedElements, elementsRefs, scrollToFirstError);
          } else {
            handleFormErrors(errors, storedElements, elementsRefs, scrollToFirstError);
          }
        }

        // Show status toasts: object or boolean true
        if (showToasts) {
          if (typeof showToasts === 'object') {
            showStatusToast(status || '', showToasts.customMessages, showToasts.ignoreStatus);
          } else {
            showStatusToast(status || '');
          }
        }
      }
    }).catch((error: Error) => {
      setIsPosting(false);
      setFormStatus({
        actionStatus: 'Unexpected error',
        error,
      });
    });
  };


  /**
   * @description Simple abstract function to merge objects if the second one is not null
   * @remarks We use it to merge formData with wizardData if we have wizardData
   */
  const mergeData = (obj1: object, obj2: object | null): object => {
    if (!obj2) return obj1;
    return { ...obj1, ...obj2 };
  };


  /**
   * @description Handles a form field submit
   * @param name - string, the name of the field
   * @param config - object with config options
   */
  const handleFieldSubmit: HandleFieldSubmitInterface = (name, config) => {
    // extract the element from storedElements: [{storedElement}]
    const storedElement = storedElements.filter((element) => ((name === element.name)));
    // get the id, so we will be able to trigger methods with ref. it does not matter for multiples, can be any of them
    const { id } = storedElement[0];

    // get the element (or one of the multiples, it does not matter)
    const element = (elementsRefs[id]?.current as FormElementRefImperative).getElement() as unknown as FormElementTypes;

    // config options
    const submitUrl = config.submitURL;
    const subMethod = config.submitMethod || 'POST';
    const { extraData } = config;
    const actionStatusExtra = config.actionStatusExtra || ` ${name}`;

    // get the field value
    const formData = getFieldValue(name, true) as object;

    // handle field submit
    if (element.checkValidity() && typeof formData === 'object') {
      if (extraData && typeof extraData === 'object') {
        postFormData(submitUrl, mergeData(formData, extraData), subMethod, actionStatusExtra);
      } else {
        postFormData(submitUrl, formData, subMethod, actionStatusExtra);
      }
    } else {
      setFormStatus({
        actionStatus: 'handleFieldSubmit invalid or you forgot to add the submitURL option!',
      });
    }
  };


  /**
   * @description Handles a form step submit (does not post).
   * It returns a form object if form is valid.
   * It returns a merged form object if 'wizardData' option has a value.
   * @param event
   */
  const handleStep = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData = checkFormValidation(event);
    const filterLabels = withFilterLabels ? getLabelsForFilters(formData) : {};

    if (typeof formData === 'object') {
      if (wizardData) {
        // if we want to merge the form data with previously collected data
        setFormStatus({
          actionStatus: 'success',
          form: mergeData(formData, wizardData),
          filterLabels,
        });
      } else {
        // form standard, without wizard
        setFormStatus({
          actionStatus: 'success',
          form: formData,
          filterLabels,
        });
      }
    } else {
      setFormStatus({
        actionStatus: 'Step invalid',
      });
    }
  };


  /**
   * @description Handles the form submit. Takes the gathered form data and posts it.
   * @param event
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const formData = checkFormValidation(event);

    if (typeof formData === 'object') {
      if (wizardData) {
        // if we have a wizard and this is the last step, merge the formData with the object collected with wizard
        postFormData(submitURL, mergeData(formData, wizardData), submitMethod);
      } else {
        // post form standard, without wizard
        postFormData(submitURL, formData, submitMethod);
      }
    } else {
      setFormStatus({
        actionStatus: 'Form invalid or you forgot to add the submitURL option!',
      });
    }
  };


  /**
   * @description Handles the form submit with a callback function. Takes the gathered form data and executes
   * the callback function with it. You wil have to manually handle the errors by using the handleFormErrors function.
   * @param event - form event
   * @param callback - function to execute; it should take the form data (object) as argument
   *
   * @example
   * // usage with useSwrMutationApi hook
   * const { trigger } = useSwrEndpointApi();
   *
   * // with extra data (for no extra data, just don't pass it)
   * const { handleCallbackSubmit } = useBjForm({ wizardData: { extraData: 'extraData' } });
   *
   * <form noValidate onSubmit={(event) => handleCallbackSubmit(event, trigger)}>
   *
   * // Import 'CallbackSubmitFunction' type if you're getting eslint errors and use it like this:
   * ...(event, trigger as CallbackSubmitFunction)...
   *
   * // how to type the SWR trigger function (with and without arguments) when passed as a prop from parent
   * interface ComponentProps {
   *   triggerWithPayload: <Data, Args>(arg: Args) => Promise<Data | void>;
   *   triggerWithoutPayload: <Data>() => Promise<Data | void>;
   * }
   */
  const handleCallbackSubmit = (event: FormEvent<HTMLFormElement>, callback: CallbackSubmitFunction): void => {
    event.preventDefault();
    const formData = checkFormValidation(event);
    const filterLabels = withFilterLabels ? getLabelsForFilters(formData) : {};

    if (typeof formData === 'object') {
      if (wizardData) {
        // if we have a wizard and this is the last step, merge the formData with the object collected with wizard
        void callback(mergeData(formData, wizardData));
        setFormStatus({
          actionStatus: 'callback done',
          form: mergeData(formData, wizardData),
          filterLabels,
        });
      } else {
        // post form standard, without wizard
        void callback(formData);
        setFormStatus({
          actionStatus: 'callback done',
          form: formData,
          filterLabels,
        });
      }
    } else {
      setFormStatus({
        actionStatus: 'Form invalid or you forgot to add the callback function!',
      });
    }
  };


  /**
   * @description Handles the errors of a form submit with a callback function.
   * @param errors - the errors object
   *
   * @example
   * // usage inside a useSwrMutationApi hook
   * ...
   * apiOptions: {
   *  onError: (errors) => handleCallbackErrors(errors);
   * },
   * ...
   */
  const handleCallbackErrors = (errors: ApiSchema<'ErrorResponse'>) => {
    if (convertOldApiErrors) {
      const convertedOldApiErrors = convertOldApiFormErrors(errors.errors);
      handleFormErrors(convertedOldApiErrors, storedElements, elementsRefs, scrollToFirstError);
    } else {
      handleFormErrors(errors.errors as ServerFieldErrorInterface[], storedElements, elementsRefs, scrollToFirstError);
    }
    // Set the form status
    setFormStatus({
      actionStatus: 'callback error',
      data: errors.errors,
    });
  };


  // export all you need in case you need to build something custom
  return {
    handleStep,
    handleSubmit,
    handleCallbackSubmit,
    handleCallbackErrors,
    handleFieldSubmit,
    record,
    checkFormValidation,
    handleFormErrors,
    convertOldApiFormErrors,
    postFormData,
    setValuesFromAPI,
    formStatus,
    storedElements,
    elementsRefs,
    getValuesFromAPI,
    formValues,
    setFormValues,
    setFlatFormValues,
    getValuesStatus,
    getFieldValue,
    getCheckedStatus,
    resetForm,
    hardResetForm,
    submitTrigger,
    updateGroupValidity,
    isPosting,
    isFetchingFormData,
    updateFormElements,
    selectAllCheckboxes,
    getFormData,
  };
};
