import { Suspense } from 'react';
import {
  Anchor, Button, ImageBj,
  Dropdown, DropdownButton, DropdownItems, DropdownItem,
} from '@components/common';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useHandleLangChange } from '@hooks/i18n/useHandleLangChange';
import { dataTestId } from '@services/data-test-id';

// Interface
// ***********************************************
interface LanguageListProps {
  withCurrentLocaleName?: boolean,
}


/**
 * @description Language selector dropdown list
 * - **withButtonSpacing** - if we should add spacing to the dropdown button
 * - **withCurrentLocaleName** - if we should display the current locale name in the dropdown button
 */
export const LanguageList = (props: LanguageListProps) => {
  // Destructure props
  const {
    withCurrentLocaleName,
  } = props;


  // Get the elements needed for a language switcher
  // ***********************************************
  const {
    locale,
    languageNames,
    languageFlags,
    languageSwitcherRef,
    switcherI18nPaths,
    isSavingLangToDB,
    handleLangChange,
  } = useHandleLangChange();

  // Flag component for the language switcher
  // ***********************************************
  const flagImage = (language: string) => (
    <ImageBj
      src={languageFlags[language]}
      width={20}
      height={18}
      alt={language?.toUpperCase() || 'flag'}
      className="mr-2 rounded-md"
    />
  );


  // Return component
  // ***********************************************
  return (
    <Suspense fallback={null}>
      <Dropdown>
        <DropdownButton
          as={Button}
          styling="none"
          className="inline-flex w-full justify-between px-3.5 text-base hover:text-primary"
          isLoading={isSavingLangToDB}
          ref={languageSwitcherRef}
          {...dataTestId('navbar.switchLanguage')}
        >
          { withCurrentLocaleName && languageNames[locale] }
          <span className="flex items-center">
            { flagImage(locale) }
            <ChevronDownIcon className="size-4 stroke-2" />
          </span>
        </DropdownButton>

        <DropdownItems
          animation="slideDown"
          anchor={{ to: 'bottom start', gap: '1rem' }}
          className="min-w-[var(--button-width)] border-t border-input/40"
        >
          {switcherI18nPaths.filter((lang) => lang[0] !== locale).map((lang) => {
            // made the variables for readability
            const langName = lang[0];
            const urlPath = lang[1];

            // IMPORTANT! For language switch never use the Next.js Link component, it will
            // prefetch on hover, the documentation is incomplete. Use Button, Anchor or 'a' tag
            // instead and do a router.push() on click.
            return (
              <DropdownItem
                as={Anchor}
                key={langName}
                href={urlPath}
                styling="text"
                color="ink"
                rounding="none"
                className="w-full !justify-start whitespace-nowrap px-5 py-3 text-sm !font-normal hover:bg-surface-50 hover:text-primary hover:no-underline md:!flex md:!w-auto md:pr-12"
                onClick={(event) => handleLangChange(event, urlPath, langName)}
              >
                {/* Flag and language name */}
                { flagImage(langName) }
                { languageNames[langName] }
              </DropdownItem>
            );
          })}
        </DropdownItems>
      </Dropdown>
    </Suspense>
  );
};
