import { useEffect, useState } from 'react';
import { Anchor } from '@components/common/Anchor/Anchor';
import { useTranslation } from 'next-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';


// Props
interface IProps {
  bannerId: string;
  heading?: string;
  pricing? : string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info' | 'secondary';
  isClosable?: boolean;
  onClose?: () => void;
  ctaText?: string;
  urlPath?: string;
  symfonyRoute?: boolean;
  symfonyParams?: object;
}

// container classes by type
const containerClassesByType = {
  success: 'bg-secondary bg-opacity-10',
  info: 'bg-info bg-opacity-10',
  warning: 'bg-warning bg-opacity-10',
  error: 'bg-error bg-opacity-10',
  secondary: 'bg-pink bg-opacity-10',
};


/**
 * @description Banner component
 *
 * **IMPORTANT**: Don't use router.push() for non React routes. If you do, we'll get errors like:
 * https://[name].bestjobs.dev/_next/static/chunks/pages/ro/account/settings.js net::ERR_ABORTED 404
 * Error: Failed to load script: /_next/static/chunks/pages/ro/account/settings.js
 *     at script.onerror (webpack-internal:///./node_modules/next/dist/client/route-loader.js:102:52)
 *
 * Just leverage Anchor component for non React routes, it takes care of this as long as you pass the
 * symfonyRoute and symfonyParams props.
 *
 * @param message
 * @param type
 * @param ctaText
 * @param ctaOnClick
 * @param isClosable
 * @param onClose
 * @param symfonyRoute
 * @param symfonyParams
 * @param urlPath
 *
 */
export const Banner = ({
  bannerId,
  heading,
  pricing,
  message,
  type,
  ctaText,
  isClosable = false,
  onClose = () => {},
  symfonyRoute = false,
  symfonyParams = {},
  urlPath,
}: IProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [isSSR, setIsSSR] = useState(true);
  const { t } = useTranslation('common');

  const sessionBannerId = `bj_banner_${bannerId}`;

  const setToStorage = (key: string, value: string | false) => (typeof window !== 'undefined' && window.localStorage.setItem(key, value.toString()));
  const getFromStorage = (key: string) => (typeof window !== 'undefined' && window.localStorage.getItem(key));

  // Save the state in session storage so that it doesn't show up again.
  // Will do this after the transition finishes.
  const saveToSessionStorage = () => {
    setToStorage(sessionBannerId, 'closed');
  };

  const onLocalClose = () => {
    setIsClosed(true);
    saveToSessionStorage();
    onClose();
  };

  // Used this to fix hydration issues caused by localStorage and SSR and client difference rendering
  useEffect(() => {
    setIsSSR(false);
  }, []);

  if (isSSR || isClosed || getFromStorage(sessionBannerId) === 'closed') {
    return null;
  }

  // Render component
  // ****************************************************************
  return (
    <>
      <div className="hidden bg-surface lg:block">
        <div className={`${containerClassesByType[type]} rounded-3xl`}>
          <div className="container flex w-full justify-between px-4 py-2">
            <div className="flex grow flex-col justify-start">
              {heading && (
              <span className="mx-3 text-md font-bold">
                {heading}
              </span>
              )}
              <p className="mx-3 text-sm">
                {message}
              </p>
            </div>

            <div className="inline-flex">
              {/* Bundle pricing */}
              {pricing && (
              <div className="mx-6 flex items-center justify-between">
                <div className="mt-0.5 flex items-center gap-1.5">
                  <strong className="text-5xl">{pricing}</strong>
                  <span className="lowercase text-ink-medium">
                    {t('label.currency.monthly')}
                  </span>
                </div>
              </div>
              )}

              {/* cta button */}
              {ctaText && urlPath && (
                <div className="my-auto">
                  <Anchor
                    href={urlPath}
                    symfonyRoute={symfonyRoute}
                    symfonyParams={symfonyParams}
                    rounding="full"
                    size="sm"
                    className="!py-2"
                    color={type === 'success' ? 'secondary' : type}
                  >
                    {ctaText}
                  </Anchor>
                </div>
              )}

              {/* close icon */}
              {isClosable && (
                <button
                  type="button"
                  className="relative mx-4 my-auto flex size-9 items-center rounded-full bg-surface p-2"
                  onClick={onLocalClose}
                  aria-label={t('global.btn.close')}
                >
                  <XMarkIcon width={24} className="text-ink" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden">
        <div className="inline-flex w-full flex-col items-start justify-center gap-4 rounded bg-fuchsia-700/10 px-6 py-4">
          <div className="inline-flex items-start justify-start gap-4 self-stretch">
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center">
              {heading && (
              <div className="text-md font-bold">
                {heading}
              </div>
              )}

              <div className="self-stretch text-sm">
                {message}
              </div>
            </div>
            {/* close icon */}
            {isClosable && (
            <button
              type="button"
              className="relative mx-4 my-auto flex size-9 items-center rounded-full bg-surface p-2"
              onClick={onLocalClose}
              aria-label={t('global.btn.close')}
            >
              <XMarkIcon width={24} className="text-ink" />
            </button>
            )}
          </div>
          <div className="inline-flex items-center justify-between gap-4 self-stretch">
            {/* Bundle pricing */}
            {pricing && (
            <div className="flex items-center justify-between">
              <div className="mt-0.5 flex items-center gap-1.5">
                <strong className="text-5xl">{pricing}</strong>
                <span className="lowercase text-ink-medium">
                  {t('label.currency.monthly')}
                </span>
              </div>
            </div>
            )}

            {/* cta button */}
            {ctaText && urlPath && (
            <div className="my-auto">
              <Anchor
                href={urlPath}
                symfonyRoute={symfonyRoute}
                symfonyParams={symfonyParams}
                rounding="full"
                type="button"
                size="sm"
                className="!py-2"
                color={type === 'success' ? 'secondary' : type}
              >
                {ctaText}
              </Anchor>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
