import { useRef, MouseEvent } from 'react';
import { useAppSelector } from '@store/index';
import { getUserHeaderInfo } from '@store/reducers/userSelector';
import { useSwrSwitchLang } from '@hooks/useSwrApi/endpoints/user';
import { setCookie, deleteCookie } from 'cookies-next';
import { getSwitcherPaths } from '@services/i18nPaths';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';
import { useExtendedRouter, useExtendedSearchParams } from '@hooks/next-routing-wrappers';

// Image imports
import flagRo from '@images/svg/flags/ro.svg';
import flagEn from '@images/svg/flags/en.svg';
import flagHu from '@images/svg/flags/hu.svg';
import flagDe from '@images/svg/flags/de.svg';
import flagFr from '@images/svg/flags/fr.svg';
import flagIt from '@images/svg/flags/it.svg';
import flagEs from '@images/svg/flags/es.svg';


/**
 * @description Locale cookie domain. It should start with dot (.) to include all subdomains and prevent API to set a non-editable cookie.
 */
const localeCookieDomain = process.env.NEXT_PUBLIC_LOCALE_COOKIE_DOMAIN || '';

/**
 * @description Name of the cookie that stores the locale
 */
const localeCookieName = 'NEXT_LOCALE';

/**
 * @description Language names for the language switcher
 */
const languageNames: { [k: string]: string } = {
  ro: 'Română',
  en: 'English',
  hu: 'Magyar',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
};

/**
 * @description Image imports for each language flag
 */
const languageFlags: { [k: string]: StaticImport } = {
  ro: flagRo as StaticImport,
  en: flagEn as StaticImport,
  hu: flagHu as StaticImport,
  de: flagDe as StaticImport,
  fr: flagFr as StaticImport,
  it: flagIt as StaticImport,
  es: flagEs as StaticImport,
};


/**
 * @description Hook with all the elements needed for a language switcher.
 *
 * **IMPORTANT!** For language switch never use the Next.js Link component, it will
 * prefetch on hover, the documentation is incomplete. Use Button, Anchor or 'a' tag
 * instead and do a router.push() on click.
 *
 * **Return values:**
 * - localeCookieDomain: string, the domain for the locale cookie ('.example.com')
 * - localeCookieName: string, the name of the locale cookie ('NEXT_LOCALE')
 * - languageNames: object, the names of the languages ('ro', 'en', 'hu')
 * - languageFlags: object, the image imports for each language flag
 * - locale: string, the current locale ('ro', 'en', 'hu')
 * - asPath: string, the current path
 * - switcherI18nPaths: object, the i18n paths for the language switcher
 * - isSavingLangToDB: boolean, the state of the SWR for saving the language to DB
 * - handleLangChange: function, the function to handle the language change
 */
export const useHandleLangChange = () => {
  // Destructure the extended router
  const {
    router,
    pathname,
    locales,
    locale,
  } = useExtendedRouter();

  // Destructure the extended search params
  const {
    searchParamsAsString,
  } = useExtendedSearchParams();

  // Get the url path with search params included (if any)
  const asPath = `${pathname}${searchParamsAsString}`;

  // Ref for the language switcher
  const languageSwitcherRef = useRef<HTMLButtonElement>(null);

  // Get the i18n or normal paths
  const switcherI18nPaths = getSwitcherPaths(asPath);

  // Get the user data from the store. If the user is not logged-in, the value is undefined.
  const userIsLoggedIn = Boolean(useAppSelector(getUserHeaderInfo));

  // SWR for language switch. Post request to API and save the new language in DB
  const {
    trigger: saveLangToDB,
    isMutating: isSavingLangToDB,
  } = useSwrSwitchLang(locale);


  /**
   * @description We want to set the cookie first and go to URL after that, so we can prevent some
   * middleware problems on homepage, where we redirect the user depending on locale cookie.
   * Do not use on Link component, it will ignore the preventDefault.
   * @param event - MouseEvent, to prevent default
   * @param url - string, the URL to push
   * @param lang - string, the language code ('ro', 'en', 'hu')
   */
  const handleLangChange = (event: MouseEvent, url: string, lang: string): void => {
    event.preventDefault();

    // Click the language switcher button to close the dropdown
    if (languageSwitcherRef?.current != null) {
      languageSwitcherRef?.current.click();
    }

    // If user is logged in, save the new language in DB. Show toast if error.
    // Send first, then set the cookie and push the new URL, there's a delay.
    if (userIsLoggedIn) {
      void saveLangToDB({ langCode: lang });
    }

    // Set the new language cookie and push the new URL
    void deleteCookie(localeCookieName, { path: '/' });
    void setCookie(localeCookieName, lang, { maxAge: 31536000, path: '/', domain: localeCookieDomain }); // 1 year
    void router.push(url, { withLocale: false });
  };


  // Return
  // *****************************************
  return {
    localeCookieDomain,
    localeCookieName,
    languageNames,
    languageFlags,
    locale,
    locales,
    asPath,
    languageSwitcherRef,
    switcherI18nPaths,
    isSavingLangToDB,
    handleLangChange,
  };
};
