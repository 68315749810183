import { useRef, useEffect } from 'react';

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: never[]) => void | Promise<void>;


/**
 * @description Hooks used for debouncing a function with a specific delay
 *
 * @example
 * const onInputChange = (input: string) => {
 *   onInputChangeDebounced(input)
 * }
 * const onInputChangeDebounced = useDebounce((inputString: string)  => {
 *   // send a request or any other action
 * })
 *
 * @param func The original, non debounced function you want to call (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */
export function useDebounce<Func extends SomeFunction>(
  func: Func,
  delay = 500,
) {
  const timer = useRef<Timer>(undefined);

  useEffect(() => () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      void func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;

  return debouncedFunction;
}
