import { useTranslation } from 'next-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Anchor, Button } from '@components/common';


// Interface
// *****************************************
interface NotEnoughCreditsProps {
  shoppingCartId?: number,
  withBackAction?: boolean,
  actionBack?: () => void,
  className?: string,
}


// Statuses for payments
// *****************************************
/**
 * @description Renders the 'not enough credits' view for the payment step if the user chooses to
 * pay with wallet but the funds are insufficient.
 * * **shoppingCartId** - The shopping cart id from the order. It will be used to redirect the user
 * to the buy credits page.
 * * **withBackAction** - If true, the 'Back' button will be shown. Default is true.
 * * **actionBack** - The action to be executed when the 'Back' button is clicked.
 * * **className** - Optional classes to be applied to the component.
 */
export const NotEnoughCredits = (props: NotEnoughCreditsProps) => {
  // Props destructuring
  const {
    shoppingCartId,
    withBackAction = true,
    actionBack,
    className = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Icon classes
  const iconBaseClasses = 'mx-auto w-20 h-20 rounded-full text-warning bg-radial-gradient';
  const iconGradientClasses = 'from-surface from-0% via-warning/30 via-60% to-warning/30 ring-8 ring-warning/10';
  const iconClasses = `${iconBaseClasses} ${iconGradientClasses}`;


  // Render component
  // *****************************************
  return (
    <div className={`mx-auto max-w-lg px-6 py-8 text-center lg:py-14 ${className}`}>
      {/* Icon */}
      <ExclamationCircleIcon className={iconClasses} />

      {/* Title */}
      <h2 className="mb-1 mt-6 text-xl font-bold">
        {t('payment.not-enough-credits.title')}
      </h2>

      {/* Message */}
      <p>{t('payment.not-enough-credits.message')}</p>

      {/* Actions */}
      <div className="mt-6 flex justify-center gap-2">
        {withBackAction && (
          <Button
            size="sm"
            color="white"
            rounding="full"
            className="!px-10"
            onClick={actionBack}
          >
            {t('global.action.back')}
          </Button>
        )}
        <Anchor
          symfonyRoute
          href="app_continue_order"
          symfonyParams={shoppingCartId ? { shoppingCartId } : {}}
          size="sm"
          color="secondary"
          rounding="full"
          className="!px-10"
        >
          {t('global.buy-credits')}
        </Anchor>
      </div>

    </div>
  );
};
