import { Ref } from 'react';
import {
  Listbox as HeadlessListbox,
  ListboxOption,
  ListboxButton,
  ListboxSelectedOption,
  ListboxProps,
} from '@headlessui/react';
import { ListboxStyledOptions as Options } from './ListboxOptions';


/** Types */
type ListboxStyledProps<TType, TActualType = string> = ListboxProps<'div', TType, TActualType> & {
  className?: string;
  ref?: Ref<HTMLDivElement>;
};


/**
 * @description Customized Listbox component. It has a default Options component that is customized
 * with animations and UI styles. This component is extended from the HeadlessUI Listbox,
 * so it has all the same props and types (and some extras as previously said).
 *
 * **Mount events for ListboxStyledOptions:**
 * - `beforeEnter` - Function to be executed before the component enters.
 * - `afterEnter` - Function to be executed after the component enters.
 * - `beforeLeave` - Function to be executed before the component leaves.
 * - `afterLeave` - Function to be executed after the component leaves.
 *
 * @example
 * // object with the data for the listbox
 * const people = [
 *   { name: 'Wade Cooper' },
 *   { name: 'Arlene Mccoy' },
 *   { name: 'Devon Webb' },
 *   { name: 'Tom Cook' },
 *   { name: 'Tanya Fox' },
 *   { name: 'Hellen Schmidt' },
 * ];
 *
 * // state for the selected item
 * const [selected, setSelected] = useState(people[0]);
 *
 * // Listbox component
 * <ListboxStyled value={selected} onChange={setSelected}>
 *
 *   // Toggle: model for ListboxStyledButton using the prop "as" to render our own component
 *   <ListboxStyledButton as={Button} styling="text" color="primary" className="outline-0">
 *      {selected.name}
 *   </ListboxStyledButton>
 *
 *   // Options: this is the customized ListboxStyledOptions component. Extra props (from the original
 *   // HeadlessUI ListboxStyledOptions component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // If you're using the `anchor` prop, put position="anchored" ui="anchored" or just don't pass them at all.
 *   <ListboxStyledOptions className="min-w-48" animation="slideDown">
 *      {people.map((person) => (
 *         <ListboxStyledOption key={person.name} value={person}>
 *            {({ active }) => (
 *              <div className={`py-2 px-3 ${active ? 'bg-surface-100' : ''}`}>
 *                 {person.name}
 *              </div>
 *            )}
 *         </ListboxStyledOption>
 *       ))}
 *  </ListboxStyledOptions>
 *
 * </ListboxStyled>
 *
 * // When the parent has `overflow: hidden` use the `anchor` prop to set the position of the ListboxStyledOptions
 * // For floated elements, use the min-w-[var(--button-width)] to set the width of the dropdown same as the button
 * <ListboxStyledOptions
 *    animation="slideDown"
 *    anchor="bottom start"
 *    className="mt-1 min-w-[var(--button-width)]"
 * >
 *   ...
 * </ListboxStyledOptions>
 *
 * // When the parent has NO `overflow: hidden` use the `position` prop to set the position of the ListboxStyledOptions
 * <ListboxStyledOptions
 *   animation="slideDown"
 *   className="mt-1"
 *   position="right"
 * >
 *   ...
 * </ListboxStyledOptions>
 */
export const ListboxStyled = <TType, TActualType = string>(props: ListboxStyledProps<TType, TActualType>) => {
  const {
    className,
    ...rest
  } = props;

  return (
    <HeadlessListbox as="div" className={className || 'relative'} {...rest} />
  );
};

export const ListboxStyledButton = ListboxButton;
export const ListboxStyledOptions = Options;
export const ListboxStyledOption = ListboxOption;
export const ListboxStyledSelectedOption = ListboxSelectedOption;
