'use client';

import { Fragment, useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Float } from '@components/common/Float/Float';
import { composePopoverPanelAnimation } from '@components/common/Popover/popoverStyles';
import { TooltipContentProps, TooltipProps, TooltipTriggerProps } from '@type/tooltip';
import { getTooltipColorScheme, getTooltipPosition } from './globalTooltipStyles';


/**
 * @description Tooltip trigger component. It wraps the children and adds mouse over and out events.
 * - `className`: *string* - Additional classes for the trigger span container.
 * - `handleMouseOver`: *function* - Function to handle mouse over event.
 * - `handleMouseOut`: *function* - Function to handle mouse out event.
 */
const TooltipTrigger = (props: TooltipTriggerProps) => {
  // destructure props
  const {
    children, handleMouseOver, handleMouseOut, className, ref,
  } = props;

  return (
    <span
      {...ref ? { ref } : {}}
      className={className}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
    >
      {children}
    </span>
  );
};

// Tooltip Content Component
// ***************************************************************
const TooltipContent = (props: TooltipContentProps) => {
  // destructure props
  const {
    colorScheme, text, contentSizeClass, ref,
  } = props;

  const colorSchemeClass = useMemo(
    () => getTooltipColorScheme(colorScheme),
    [colorScheme],
  );

  return (
    <span
      {...ref ? { ref } : {}}
      className={`relative block w-fit rounded px-2 py-1 text-center text-sm font-normal shadow-md ${colorSchemeClass} ${contentSizeClass || 'max-w-72'}`}
    >
      {text}
    </span>
  );
};

// Tooltip Component
// ***************************************************************
/**
 * @description Simple tooltip component, based on position and colorScheme.
 *
 * If you need to show the tooltip in a parent with overflow: hidden,
 * you can use the `floating` prop, which is based on HeadlessFloat component.
 * Refer to Headless UI Float documentation for more info here: https://headlessui-float.vercel.app/react/quick-start.html.
 *
 * Props:
 * - **children:** ReactNode - the content that will trigger the tooltip
 * - **position:** 'top' | 'bottom' | 'left' | 'right' - the position of the tooltip
 * - **tooltipText:** string - the text to display in the tooltip
 * - **colorScheme:** 'primary' | 'secondary' | 'default' | 'error' | 'black' | 'gray' | 'pink' - the color scheme of the tooltip
 * - **displayStyle:** 'inline-block' | 'block' - the display style of the tooltip
 * - **forceHideTooltip:** boolean - whether to force hide the tooltip
 * - **forceShowTooltip:** boolean - whether to force show the tooltip
 * - **floating:** boolean - whether to show the tooltip in a parent with overflow: hidden
 * - **useFloatingPortal:** boolean - whether to render the floating element to the end of <body>.
 * - **className:** string - additional classes for the tooltip; note that if you're using this, the default classes will be overwritten
 * - **contentSizeClass:** string - add a tailwind class to control the size of the tooltip content
 * - **triggerContainerClass:** string - additional classes for the trigger span container
 *
 * @example
 * <Tooltip tooltipText="This is a tooltip for special occasions" position="top" colorScheme="primary">
 *   <Button>Primary</Button>
 * </Tooltip>
 *
 *
 * // If you need to show the popover in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * // the tooltip panel position, you can use the `floating` prop, which is based on HeadlessFloat component.
 * <div className="overflow-hidden">
 *   <Tooltip tooltipText="This is a tooltip for special occasions" position="top" colorScheme="primary" floating>
 *     <Button>Primary</Button>
 *   </Tooltip>
 * </div>
 */
export const Tooltip = (props: TooltipProps) => {
  // Destructure props
  const {
    children,
    position = 'bottom',
    tooltipText,
    colorScheme = 'default',
    displayStyle = 'inline-block',
    forceShowTooltip = false,
    forceHideTooltip = false,
    floating = false,
    className,
    contentSizeClass,
    triggerContainerClass,
  } = props;

  // Tooltip animation
  const TOOLTIP_ANIMATION_NAME = 'fade';

  // Floating tooltip needs some offset, equivalent with Tailwind spacing value of 2 (mb-2, ml-2, mt-2, mr-2)
  // https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
  const FLOATING_TOOLTIP_OFFSET = 8;

  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);

  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  const positionClass = useMemo(
    () => getTooltipPosition(position),
    [position],
  );

  const tooltipAnimation = composePopoverPanelAnimation(TOOLTIP_ANIMATION_NAME);

  return (
    <span className={className || `relative ${displayStyle === 'inline-block' ? 'inline-block w-fit' : 'block w-full'}`}>
      {floating ? (
        <Float
          show={Boolean(forceShowTooltip || (showTooltip && !forceHideTooltip))}
          strategy="fixed"
          placement={position}
          offset={FLOATING_TOOLTIP_OFFSET}
        >
          {({ reference, floatingRef, floatingStyles }) => (
            <>
              {/* Trigger: Apply reference ref */}
              <TooltipTrigger
                ref={reference}
                className={triggerContainerClass || displayStyle}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
              >
                {children}
              </TooltipTrigger>

              {/* Floating Content with Transition */}
              <Transition
                as={Fragment}
                show={Boolean(forceShowTooltip || (showTooltip && !forceHideTooltip))}
                enter="transition ease-out duration-200"
                enterFrom={tooltipAnimation.hiddenState}
                enterTo={tooltipAnimation.visibleState}
                leave="transition ease-in duration-150"
                leaveFrom={tooltipAnimation.visibleState}
                leaveTo={tooltipAnimation.hiddenState}
              >
                {/* This div receives the floating ref and styles for positioning */}
                <div
                  ref={floatingRef}
                  style={floatingStyles}
                  className="z-10"
                >
                  <TooltipContent
                    colorScheme={colorScheme}
                    text={tooltipText}
                    contentSizeClass={contentSizeClass}
                  />
                </div>
              </Transition>
            </>
          )}
        </Float>
      ) : (
        <>
          <TooltipTrigger className={triggerContainerClass || displayStyle} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut}>
            {children}
          </TooltipTrigger>
          <Transition
            as={Fragment}
            show={Boolean(forceShowTooltip || (showTooltip && !forceHideTooltip))}
            enter="transition ease-out duration-200"
            enterFrom={tooltipAnimation.hiddenState}
            enterTo={tooltipAnimation.visibleState}
            leave="transition ease-in duration-150"
            leaveFrom={tooltipAnimation.visibleState}
            leaveTo={tooltipAnimation.hiddenState}
          >
            <span className={`pointer-events-none absolute z-10 flex w-full min-w-32 items-center ${positionClass}`}>
              <TooltipContent colorScheme={colorScheme} text={tooltipText} contentSizeClass={contentSizeClass} />
            </span>
          </Transition>
        </>
      )}
    </span>
  );
};
