import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Fragment, isValidElement } from 'react';
import { ModalComponent, ModalBaseProps } from 'src/types/modal-types';
import {
  ModalBackdrop, ModalHeader, ModalBody, ModalFooter, ModalStyles,
} from './index';


// Modal main component
// *************************************************************************************************
const ModalBase = (props: ModalBaseProps) => {
  const {
    className = '',
    size = 'md',
    header = '',
    headerClasses = '',
    body = '',
    bodyClasses = '',
    footer = '',
    footerClasses = '',
    children = '',
    backdrop = true,
    centered = true,
    initialFocus,
    onClose,
    isOpen,
    containerClasses,
    beforeEnter,
    afterEnter,
    beforeLeave,
    afterLeave,
    ...restProps
  } = props;


  // Modal window sizes & classes
  const sizeClasses = ModalStyles.modalSizes[size];
  const modalClasses = `${ModalStyles.modalBase} ${sizeClasses} ${className}`;


  // Render modal
  // **************************************
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog className="relative z-40" onClose={onClose} initialFocus={initialFocus} {...restProps}>

        {/* Modal backdrop - short circuit display condition (true/false && true) */
          backdrop && <ModalBackdrop />
        }

        {/* Modal container; add window padding for not full page modals */}
        <div className={`fixed inset-0 w-screen overflow-y-auto${containerClasses ? ` ${containerClasses}` : ''}`}>
          <div className={`flex min-h-full ${centered ? 'items-center' : 'items-start pt-2 sm:pt-10'}  justify-center ${size !== 'full' ? 'p-2' : ''}`}>
            {/* Modal animation classes */}
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
              beforeEnter={beforeEnter}
              afterEnter={afterEnter}
              beforeLeave={beforeLeave}
              afterLeave={afterLeave}
            >
              {
                // For each of header, body, footer props we're going to check if they are React Components using the isValidElement(object) function;
                // If they are, we display the component, else, if we have strings inside those props we display them inside the defined Modal components
                // Display nothing if short circuit condition not met (prop not passed)
              }
              <DialogPanel className={modalClasses}>
                { // Modal header
                  isValidElement(header)
                    ? header
                    : header && <ModalHeader content={header} className={headerClasses} onClose={onClose} />
                }
                { // Modal body
                  isValidElement(body)
                    ? body
                    : body && <ModalBody content={body} className={bodyClasses} onClose={onClose} />
                }
                { // if you feel like using <Modal>your content directly</Modal>
                  children
                }
                { // Modal footer
                  isValidElement(footer)
                    ? footer
                    : footer && <ModalFooter content={footer} className={footerClasses} onClose={onClose} />
                }
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>

      </Dialog>
    </Transition>
  );
};


/**
 * @description Modal component. You can pass the header, body, footer via props or using the dot notation
 * (Modal.Header, Modal.Body, Modal.Footer, Modal.Backdrop). Modal props are:
 *
 * @param {boolean} isOpen - The state for managing opened/closed modal.
 * @param {function} onClose - The close modal function; it will be automatically passed to header.
 * @param {string} className - Modal optional classes (if default classes are not enough)
 * @param {string} size - Modal size (sm, md, lg, xl, full). Default: md.
 * @param {boolean} centered - If the modal should be centered on the screen. Default: true.
 * @param {boolean} backdrop - If you want to display the modal backdrop. Default: true.

 * @param {string} header - Modal header content when not using the dot notation. You can pass a React Component or a string.
 * @param {string} headerClasses - Modal header classes if you're passing the header as a string/component (not dot notation).
 * @param {string} body - Modal body content when not using the dot notation. You can pass a React Component or a string.
 * @param {string} bodyClasses - Modal body classes if you're passing the header as a string/component (not dot notation).
 * @param {string} footer - Modal footer content when not using the dot notation. You can pass a React Component or a string.
 * @param {string} footerClasses - Modal footer classes if you're passing the header as a string/component (not dot notation).
 *
 * @param {function} beforeEnter - Function to be executed before the modal enters.
 * @param {function} afterEnter - Function to be executed after the modal enters.
 * @param {function} beforeLeave - Function to be executed before the modal leaves.
 * @param {function} afterLeave - Function to be executed after the modal leaves.
 *
 * **Important notes:**
 * 1. Sticky: if you want to use sticky elements inside the modal, override the overflow-hidden class with !overflow-visible.
 * 2. Fixed: if you want to use fixed elements inside the modal, override the transform class with !transform-none. You will
 * lose the modal transform animation, so if you want to keep it, you should implement a different html structure.
 *
 * @example
 * // import Modal component
 * import { Modal } from '@components/common';
 *
 * // Modal with header, body, footer passed as props
 * // ************************************************
 * const [isOpen, setIsOpen] = useState(false);
 *
 * <Button onClick={() => setIsOpen(true)}>Open modal</Button>
 *
 * <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} header="Modal header" body="Modal body" footer="Modal footer" />
 *
 *
 * // Modal with header, body, footer passed as dot notation components
 * // We also use the useBjModal hook for convenience
 * // *****************************************************************
 * const { isOpen, toggleModal } = useBjModal();
 *
 * <Button onClick={toggleModal}>Open modal</Button>
 *
 * <Modal isOpen={isOpen} onClose={toggleModal}>
 *   <Modal.Header onClose={toggleModal}>Modal header</Modal.Header>
 *   <Modal.Body>Modal body</Modal.Body>
 *   <Modal.Footer>Modal footer</Modal.Footer>
 * </Modal>
 */
const StyledDialog: ModalComponent = Object.assign(ModalBase, {
  Backdrop: ModalBackdrop,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});

export { StyledDialog as Modal };
