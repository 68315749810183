import { DrawerBaseProps } from '@type/drawer-types';
import {
  Dialog, DialogPanel, Transition, TransitionChild,
} from '@headlessui/react';
import { Fragment } from 'react';
import { ModalBackdrop } from '@components/common/Modal/ModalBackdrop';
import { drawerStyles } from './_drawerStyles';


/**
 * @description Drawer component, similar to the modal but with a different animation and position (sides). Drawer is used for
 * side menus, filters, and other side positioned components. Props are similar to the modal component:
 *
 * * **isOpen** {boolean} - The state for managing opened/closed the drawer.
 * * **onClose** {function} - The close drawer function.
 * * **className** {string} - Drawer optional classes (if default classes are not enough).
 * * **size** {string} - Drawer size (xs, sm, md, lg, xl, 2xl). Default: md.
 * * **position** {string} - Drawer position (left, right, top, bottom). Default: right.
 * * **backdrop** {boolean} - If you want to display the drawer backdrop. Default: true.
 * * **panelClasses** {string} - Custom classes to be added to drawer panel, for styling purposes.
 * * **beforeEnter** {function} - Function to be executed before the drawer enters.
 * * **afterEnter** {function} - Function to be executed after the drawer enters.
 * * **beforeLeave** {function} - Function to be executed before the drawer leaves.
 * * **afterLeave** {function} - Function to be executed after the drawer leaves.
 *
 * @example
 * import { Drawer } from '@components/common';
 * import { useBjModal } from '@hooks/common/useBjModal';
 *
 * // get the states and functions from the hook
 * const {isOpen: drawerOpen, toggleModal: toggleDrawer} = useBjModal();
 *
 * // Render
 * <Drawer onClose={toggleDrawer} isOpen={drawerOpen} position="right" size="md">
 *   <div className="p-4 relative bg-surface">
 *     your content here
 *   </div>
 * </Drawer>
 */
export const Drawer = (props: DrawerBaseProps) => {
  const {
    className = '',
    children,
    backdrop = true,
    onClose,
    isOpen,
    size = 'md',
    position = 'right',
    panelClasses = '',
    beforeEnter,
    afterEnter,
    beforeLeave,
    afterLeave,
    ...restProps
  } = props;


  // Classes
  // **************************************
  const isX = position === 'left' || position === 'right';
  const baseClasses = drawerStyles.drawerPosition[position];
  const sizeClasses = isX ? drawerStyles.drawerSizesX[size] : drawerStyles.drawerSizesY[size];
  const drawerAnimation = drawerStyles.drawerAnimation[position];
  const drawerClasses = `flex fixed ${baseClasses} ${className}`;


  // Render drawer
  // **************************************
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog className="relative z-40" onClose={onClose} {...restProps}>

        {/* Drawer backdrop - short circuit display condition (true/false && true) */
          backdrop && <ModalBackdrop />
        }

        {/* Drawer container */}
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className={drawerClasses}>
              {/* Drawer animation classes */}
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-300"
                leave="transform transition ease-in-out duration-300"
                {...drawerAnimation}
                beforeEnter={beforeEnter}
                afterEnter={afterEnter}
                beforeLeave={beforeLeave}
                afterLeave={afterLeave}
              >
                <DialogPanel className={`w-screen ${sizeClasses} overflow-y-auto bg-surface shadow-drawer ${panelClasses}`}>
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>

      </Dialog>
    </Transition>
  );
};
