import { RefObject, useLayoutEffect, useState } from 'react';
/**
 * @description Hook to check if an element is overflowing. Returns boolean.
 * Can call a callback function upon change of the boolean.
 * Ref param can be any html element, ex: HTMLDivElement, HTMLParagraphElement etc
 * ---------------------------
 * - `ref` - ref for the element
 * - `overflowDirection` - whether to check for vertical overflow or horizontal overflow
 * - `callback` - optional callback to trigger upon overflow change
 * @example
 * Basic usage: *
 * const elementRef: RefObject<HTMLDivElement> = createRef();
 * const isOverflow = useIsOverflown(ref, 'vertical', someFunction);
 * <div ref={elementRef} />
 */

export const useIsOverflown = (ref: RefObject<HTMLElement | null>, overflowDirection: 'vertical' | 'horizontal', callback?: () => void) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      if (current !== null) {
        const hasOverflow = overflowDirection === 'vertical' ? current.scrollHeight > current.clientHeight : current.scrollWidth > current.clientWidth;
        setIsOverflow(hasOverflow);
        if (callback) callback();
      }
    };

    if (current) {
      trigger();
    }
  }, [callback, ref, overflowDirection]);

  return isOverflow;
};
