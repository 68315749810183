import { Ref } from 'react';
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverBackdrop,
  PopoverProps,
} from '@headlessui/react';
import { PopoverStyledPanel as PopoverPanel } from './PopoverStyledPanel';


/** Types */
interface PopoverStyledProps extends PopoverProps {
  className?: string;
  ref?: Ref<HTMLDivElement>;
}


/**
 * @description Customized Popover component. It has a default Panel component that is customized
 * with animations, positions and UI styles. This component is extended from the HeadlessUI Popover,
 * so it has all the same props and types (and some extras as previously said).
 *
 * **Mount events for PopoverStyledPanel:**
 * - `beforeEnter` - Function to be executed before the component enters.
 * - `afterEnter` - Function to be executed after the component enters.
 * - `beforeLeave` - Function to be executed before the component leaves.
 * - `afterLeave` - Function to be executed after the component leaves.
 *
 * @example
 * <PopoverStyled>
 *
 *   // Toggle: model for Popover.Button using the prop "as" to render our own component
 *   <PopoverStyledButton as={Button} styling="text" size="sm" color="ink" className="outline-0">
 *     Toggle popover
 *   </PopoverStyledButton>
 *
 *   // Panel: this is the customized PopoverPanel component. Extra props (from the original
 *   // HeadlessUI PopoverPanel component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // If you're using the `anchor` prop, put position="anchored" ui="anchored" or just don't pass them at all.
 *   <PopoverStyledPanel animation="slideUp" position="left" className="p-4">
 *      <h3 className="text-2xl">Panel content</h3>
 *      <p className="text-lg">Lorem ipsum dolor sit amet.</p>
 *
 *      // On click this will not close the popover
 *      <LinkStyled href="/some-page" styling="text">
 *        Some link
 *      </LinkStyled>
 *
 *      // On click this will close the popover (this is in the HeadlessUi Popover documentation)
 *      <PopoverStyledButton as={LinkStyled} href="/another-page" styling="text">
 *        Another link
 *      </PopoverStyledButton>
 *   </PopoverStyledPanel>
 *
 * </PopoverStyled>
 *
 *
 * // When the parent has `overflow: hidden` use the `anchor` prop to set the position of the PopoverStyledPanel
 * // For floated elements, use the min-w-[var(--button-width)] to set the width of the dropdown same as the button
 * <PopoverStyledPanel
 *    animation="slideDown"
 *    anchor="bottom start"
 *    className="mt-1 min-w-[var(--button-width)]"
 * >
 *   ...
 * </PopoverStyledPanel>
 *
 * // When the parent has NO `overflow: hidden` use the `position` prop to set the position of the PopoverStyledPanel
 * <PopoverStyledPanel
 *   animation="slideDown"
 *   className="mt-1"
 *   position="right"
 * >
 *   ...
 * </PopoverStyledPanel>
 */
export const PopoverStyled = (props: PopoverStyledProps) => {
  const {
    className,
    ...rest
  } = props;

  return (
    <Popover as="div" className={className || 'relative'} {...rest} />
  );
};

export const PopoverStyledButton = PopoverButton;
export const PopoverStyledGroup = PopoverGroup;
export const PopoverStyledBackdrop = PopoverBackdrop;
export const PopoverStyledPanel = PopoverPanel;
